import React from "react";
import AuthLayout from "../../../shared/components/authLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField, InputLabel, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCreatePasswordDetails } from "../../../services/BAuth";
import { ClipLoader } from "react-spinners";
import FErrorMessage from "../../../shared/components/FErrorMessage";
import { useTranslation } from "react-i18next";

const CreatePassword = () => {
  const { t } = useTranslation();
  let params = useParams();
  const id = params?.id;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.auth?.createPassword?.loading);

  return (
    <AuthLayout>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(getCreatePasswordDetails(values, id, navigate));
        }}
        validationSchema={Schema}
      >
        {(props) => (
          <Box
            padding={{ xs: "10px 20px", md: 0 }}
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              textAlign="center"
              fontSize={{ xs: 25, sm: 32, md: 36, lg: 40 }}
              fontWeight={700}
              color="#101828"
              mb={3}
            >
              {t("Auth.createPassword")}
            </Typography>
            <form autoComplete="off" onSubmit={props.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>{t("Signup.password")}</InputLabel>
                  <TextField
                    fullWidth
                    placeholder={t("Signup.password")}
                    className="authfield"
                    value={props.values.password}
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="password"
                    error={
                      props.touched.password && Boolean(props.errors.password)
                    }
                    required
                  />
                  <FErrorMessage name="password" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>{t("Signup.confirmPassword")}</InputLabel>
                  <TextField
                    fullWidth
                    placeholder={t("Signup.confirmPassword")}
                    className="authfield"
                    value={props.values.confirmPassword}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    name="confirmPassword"
                    error={
                      props.touched.confirmPassword &&
                      Boolean(props.errors.confirmPassword)
                    }
                    type="password"
                    required
                  />
                  <FErrorMessage name="confirmPassword" />
                </Grid>
              </Grid>
              <Button
                disabled={loading}
                className="containedPrimary"
                variant="contained"
                sx={{
                  width: "50%",
                  margin: "auto",
                  display: "flex",
                  marginTop: "20px",
                  "&:hover": {
                    backgroundColor: "#35A6AE !important",
                  },
                }}
                onClick={props.handleSubmit}
              >
                {loading ? (
                  <ClipLoader size={25} color="white" loading />
                ) : (
                  <>{t("Auth.setPassword")}</>
                )}
              </Button>
              <ToastContainer />
            </form>
            <Box
              pt={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="text"
                sx={{ color: "#35A6AE" }}
                onClick={() => navigate("/login", { replace: true })}
              >
                <ArrowBack /> {t("Auth.backToLogin")}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default CreatePassword;
