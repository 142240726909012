import React from "react";
import "./footer.scss";
import { useLocation, useNavigate } from "react-router";
import { Container } from "@mui/system";
import landingMonitor from "../../../assets/images/landingMonitor.png";
import { useTranslation } from "react-i18next";
import inputLines from "../../../assets/connectRxImages/inputLines.svg";
import { Input, Button, Grid, Box, TextField, Typography } from "@mui/material";

const Newsletter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Container>
      <Box sx={{ width: { xs: "100%", sm: "85%", md: "85%" }, margin: "auto" }}>
        <Box
          sx={{
            background: "#35A6AE",
            borderRadius: "53px",
            padding: "70px 0 50px",
            // minHeight: "320px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            zIndex: "9",
            marginBottom: "-61px",
          }}
        >
          <Box
            textAlign={"center"}
            marginBottom={{ xs: "20px", sm: "30px", md: "40px" }}
          >
            <Typography
              fontSize={{ xs: "28px", sm: "28px", md: "33px" }}
              fontWeight={"700"}
              color={"#fff"}
            >
              SUBSCRIBE.
            </Typography>
            <Typography
              fontSize={{ xs: "13px", sm: "13px", md: "15px" }}
              fontWeight={"400"}
              color={"#fff"}
            >
              to Our NewsLetter
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            width={{ xs: "80%", sm: "70%", md: "50%" }}
          >
            <Input
              placeholder="Enter Your Email"
              disableUnderline
              // placeholder={t("newsLetter.enterEmail")}
              sx={{
                width: { xs: "100%", sm: "100%", md: "70%" },
                margin: "auto",
                borderBottom: "1px solid #fff ",
                color: "white",
                zIndex: 0,
                "&::before": {
                  borderBottom: "1px solid white !important",
                },
                "&::after": {
                  content: "''",
                  position: "absolute",
                  left: "unset !important",
                  right: "-117px",
                  bottom: "-3px !important",
                  zIndex: 99,
                  transform: "rotate(-5deg)",
                  borderBottom: "none !important",
                  backgroundImage: `url(${inputLines})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  height: "211px",
                  width: "252px",
                  display: { md: "block", sm: "none", xs: "none" },
                },
                "&::placeholder": {
                  color: "white",
                },
              }}
            />
            <Button
              sx={{
                background: "#fff",
                cursor: "pointer",
                width: { sm: "50%", md: "30%" },
                margin: "auto",
                borderRadius: "15px",
                color: "#35A6AE",
                fontSize: { xs: "12px", sm: "13px", md: "15px" },
                fontWeight: "700",
                "&:hover": {
                  background: "#1c4445",
                  color: "#fff"
                },
              }}
            >
              {t("newsLetter.subscribe")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Newsletter;
