import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container, width } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Footer from "../../shared/components/footer";
import AboutBg from "../../assets/connectRxImages/AboutBg.svg";
import { Card, Grid, List, Button, Divider, Input } from "@mui/material";
import * as Yup from "yup";
import FaqResponsiveHeader from "./faqHeader";
import Icon1 from "../../assets/businessPage/Button1.svg";
import Icon2 from "../../assets/businessPage/Button2.svg";
import Icon3 from "../../assets/businessPage/Button3.svg";
import Icon4 from "../../assets/businessPage/Button4.svg";
import { useTranslation } from "react-i18next";
import ResponsiveAppBar from "./businessHeader";
import ContactUsLine2 from "../../assets/connectRxImages/ContactUsLine.svg";
import ContactUsLine from "../../assets/connectRxImages/ContactUs_Line.png";
import upperIcon from "../../assets/connectRxImages/upperIcon.svg";
import BottomIcon from "../../assets/connectRxImages/BottomIcon.svg";
import ContactUsBg from "../../assets/connectRxImages/ContactUsBg.svg";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import Newsletter from "../../shared/components/footer/newsletter";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";
// import AnimatedComponent from "./Test";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  message: Yup.string().required("Message is required"),
});

const CardData = [
  {
    id: 1,
    title: "About us",
    descreption: "Discover our platform, partners and products",
  },
  {
    id: 2,
    title: "NewsRoom ",
    descreption: "Find the latest news, product update and awards",
  },
  {
    id: 3,
    title: "Blog ",
    descreption: "explore our thoughts on the healthTech industry",
  },
];

const ContactUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = (values) => {
    console.log("values", values);
  };

  const cardList = [
    {
      icon: (
        <img src={Icon1} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Product",
      detail: "contactUs.Details",
      email: "contactUs.email1",
    },
    {
      icon: (
        <img src={Icon2} style={{ height: "40px", marginBottom: "30px" }} />
      ),

      title: "contactUs.Sales",
      detail: "contactUs.Details",
      email: "contactUs.email2",
    },
    ,
    {
      icon: (
        <img src={Icon3} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Partnerships",
      detail: "contactUs.Details",
      email: "contactUs.email3",
    },
    ,
    {
      icon: (
        <img src={Icon4} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Help",
      detail: "contactUs.Details",
      email: "contactUs.email4",
    },
  ];

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      {/* <BackArrowButton /> */}
      <Box
        sx={{
          backgroundImage: `url(${AboutBg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "126px 0",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"28px"}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography variant="h2" color={"white"}>
              Contact Us
            </Typography>
            <Divider sx={{ border: "2px solid white", width: "67%" }} />
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Typography variant="h5" color={"white"}>
              Home
            </Typography>
            <Typography color={"white"}>|</Typography>
            <Typography variant="h5" color={"white"}>
              Contact Us
            </Typography>
          </Box>
        </Box>
      </Box>
      <Container sx={{ marginTop: "30px", marginBottom: "30px" }}>
        <Grid container alignItems={"center"}>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Box
              padding={{
                xs: "100px 30px 100px 50px",
                sm: "30px",
                md: "100px 50px",
              }}
              sx={{
                backgroundImage: `url(${ContactUsBg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography
                variant="h2"
                fontSize={{ xs: "28px", sm: "40px", md: "50px" }}
                color={"#fff"}
                maxWidth={"336px"}
              >
                How Can We Help You?
              </Typography>
              <Box
                display={"flex"}
                gap={"50px"}
                position={"relative"}
                flexDirection={"column"}
                sx={{
                  "&::before": {
                    content: "''",
                    backgroundImage: `url(${ContactUsLine})`,
                    // width: "50px",
                    width: ["20px", "50px", "50px"],
                    height: "90%",
                    marginTop: "15px",
                    position: "absolute",
                    left: "-39px",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    // backgroundSize: ["contain", "70%", "70%"],
                    backgroundRepeat: "no-repeat",
                  },
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                  <Typography
                    fontSize={{ xs: "24px", sm: "30px", md: "40px" }}
                    fontWeight={"700"}
                    color={"#fff"}
                  >
                    Product
                  </Typography>
                  <Typography
                    fontSize={{
                      xs: "16px",
                      sm: "20px",
                      md: "24px",
                    }}
                    fontWeight={"500"}
                    color={"#fff"}
                    maxWidth="283px"
                  >
                    Tell us how our product team can help you!
                  </Typography>
                </Box>
                <Typography
                  variant="p"
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  fontSize={{ xs: "14px", sm: "20px", md: "24px" }}
                  fontWeight={"400"}
                  color={"#fff"}
                >
                  <LocalPostOfficeOutlinedIcon fontSize="20px" />
                  hello@connectrx.com
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7}>
            <Box
              padding={{
                xs: "75px 26px 65px",
                sm: "75px 40px 65px",
                md: "75px 55px 65px",
              }}
              sx={{
                background: "#35A6AE",
                display: "flex",
                gap: "30px",
                flexDirection: "column",
                position: "relative",
                "&::after": {
                  content: "''",
                  backgroundImage: `url(${ContactUsLine2})`,
                  width: { xs: "30px", md: "50px" },
                  height: "58%",
                  top: "23%",
                  position: "absolute",
                  right: "0px",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                },
              }}
            >
              <img
                src={BottomIcon}
                alt=""
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "18px",
                }}
              />
              <img
                src={upperIcon}
                alt=""
                style={{ position: "absolute", bottom: "10px", right: "18px" }}
              />
              <Box display={"flex"} flexDirection={"column"} gap={"30px"}>
                <Typography
                  variant="h2"
                  fontSize={{ xs: "30px", sm: "40px", md: "55px" }}
                  color={"#fff"}
                  fontWeight={"800"}
                >
                  ConnectRX
                </Typography>
                <Typography
                  variant="p"
                  fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
                  color={"#fff"}
                  fontWeight={"400"}
                  maxWidth={"450px"}
                >
                  Our dedicated team is here to support you on your journey
                  towards healthcare excellence. Contact us for personalized
                  solutions and expert guidance."
                </Typography>
              </Box>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  color: "white",
                }}
              >
                <label htmlFor="" style={{ textTransform: "uppercase" }}>
                  Full Name
                </label>
                <Input
                  type="text"
                  placeholder="Your Full Name"
                  disableUnderline
                  sx={{
                    width: { xs: "90%", md: "77%" },
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderBottom: "2px solid white",
                  }}
                />
                <label htmlFor="" style={{ textTransform: "uppercase" }}>
                  Email address
                </label>
                <Input
                  type="email"
                  placeholder="Your Email Address"
                  disableUnderline
                  sx={{
                    width: { xs: "90%", md: "77%" },
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderBottom: "2px solid white",
                  }}
                />
                <label htmlFor="" style={{ textTransform: "uppercase" }}>
                  Phone Number
                </label>
                <Input
                  type="number"
                  placeholder="Your Phone Number "
                  disableUnderline
                  sx={{
                    width: { xs: "90%", md: "77%" },
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderBottom: "2px solid white",
                  }}
                />
                <label htmlFor="" style={{ textTransform: "uppercase" }}>
                  Message
                </label>
                <Input
                  type="text"
                  placeholder="Drop Your Message"
                  disableUnderline
                  sx={{
                    width: { xs: "90%", md: "77%" },
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderBottom: "2px solid white",
                  }}
                />
                <Button
                  sx={{
                    width: { xs: "70%", md: "29%" },
                    textTransform: "capitalize",
                    padding: "10px 0",
                    backgroundColor: "#fff",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#35A6AE",
                    borderRadius: "16px",
                    marginTop: "20px",
                    "&:hover": {
                      background: "#1c4445",
                      color: 'white'
                    }
                  }}
                >
                  send message
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          gap={"50px"}
          marginTop={"40px"}
          flexDirection={"column"}
        >
          <Typography
            variant="h4"
            textAlign={"center"}
            color={"#35A6AE"}
            textTransform={"capitalize"}
            sx={{ fontWeight: "700" }}
          >
            Find out more on ConnectRx
          </Typography>
          <Grid container>
            {CardData.map((el, ind) => (
              <Grid
                key={ind}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                spacing={3}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"15px"}
                  sx={{
                    transition: "0.5 linear",
                    padding: "25px 0",
                    "&:hover": {
                      cursor: "pointer",
                      borderRadius: "15px",
                      background: "#35A6AE",
                      color: "white !important",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    color={"#191718"}
                    sx={{
                      fontWeight: "600",
                      "&:hover": {
                        color: "white"
                      },
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "20.392px",
                      fontWeight: "400",
                      maxWidth: "282px",
                      textAlign: "center",
                      textTransform: "capitalize",
                      color: "#191718",
                      "&:hover": {
                        color: "white"
                      },
                    }}
                  >
                    {el.descreption}
                  </Typography>
                  <Button
                    onClick={() => navigate("/about-us")}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#191718",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "white"
                      },
                    }}
                  >
                    Learn More <ArrowRightAltIcon fontSize={"20px"} />
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {/* <AnimatedComponent /> */}
      <Newsletter />
      <Footer />
    </>
  );
};
export default ContactUs;
