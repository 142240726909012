export function findURLParam(key, location) {
  let param,
    params_arr = [],
    queryString = location.indexOf("?") !== -1 ? location.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        return params_arr[i];
      }
    }
  }
}

export const isLogin = () => {
  if (localStorage.getItem("token")) {
    return true;
  }
  return false;
};

export const metaConfig = {
  "/": {
    title:
      "Medical Professionals B2B Marketplace | Buy, Sell, and Save | ConnectRx",
    description:
      "Explore ConnectRx, the premier B2B marketplace for medical professionals. Buy, sell, and save on surplus medical supplies and medications in a secure and efficient environment. Join us to foster trust, promote savings, and transform your healthcare transactions.",
  },
  "/about-us": {
    title:
      "About ConnectRx | Fostering Trust and Savings in Healthcare Transactions",
    description:
      "Learn about ConnectRx, our mission to promote trust and savings in healthcare, and how we're transforming transactions for medical professionals.",
  },
  "/contact-us": {
    title:
      "Contact ConnectRx | Get in Touch with Your Healthcare Exchange Partner",
    description:
      "Contact ConnectRx to connect with our team for inquiries, partnerships, and assistance. We're here to help you in the healthcare field.",
  },
  "/privacy-policy": {
    title:
      "Privacy Policy | ConnectRx - Your Trusted Healthcare Exchange Platform",
    description:
      "Review our privacy policy to understand how ConnectRx protects your data and ensures a secure and confidential healthcare marketplace.",
  },
  "/term-&-conditions": {
    title:
      "Terms and Conditions | ConnectRx - Your B2B Healthcare Exchange Partner",
    description:
      "Read the terms and conditions governing your use of ConnectRx, your trusted B2B exchange platform for healthcare professionals.",
  },
  "/blogs": {
    title:
      "ConnectRx Blog | Stay Informed About Healthcare Trends and Insights",
    description:
      "Explore our blog for the latest healthcare trends, insights, and updates in the medical industry.",
  },
  "/approach": {
    title: "Our Approach | ConnectRx - Transforming Healthcare Transactions",
    description:
      "Discover ConnectRx's approach to transforming healthcare transactions, fostering trust, and promoting savings for medical professionals.",
  },
  "/testimonial": {
    title:
      "Testimonials | ConnectRx - Real Stories, Real Success in Healthcare",
    description:
      "Read real testimonials from satisfied users who have experienced success and trust with ConnectRx in the healthcare industry.",
  },
  "/whyUs": {
    title: "Why Choose ConnectRx? | B2B Healthcare Exchange Platform",
    description:
      "Explore the reasons to choose ConnectRx as your trusted B2B healthcare exchange platform, offering security, efficiency, and savings.",
  },
  "/faq": {
    title: "FAQ | B2B Healthcare Exchange Platform | ConnectRx",
  },
  "/pricing": {
    title:
      "ConnectRx Pricing | Transparent and Competitive Rates for Healthcare Professionals",
    description:
      "Explore ConnectRx's transparent and competitive pricing for our B2B healthcare exchange platform. Discover cost-effective solutions for your medical supply transactions.",
  },
  "/products": {
    title:
      "ConnectRx Product - Your Secure and Efficient Healthcare Exchange Solution",
    description:
      "Discover the ConnectRx product, a secure and efficient B2B healthcare exchange platform, designed to meet the needs of medical professionals. Streamline your transactions with confidence.",
  },
};
