import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "./customFaq.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ControlPointSharpIcon from "@mui/icons-material/ControlPointSharp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import faq_general_question from "../../assets/connectRxImages/faq_general_question.png"
import faq_using_your_accont from "../../assets/connectRxImages/faq_using_your_accont.png"
import faq_med_eq from "../../assets/connectRxImages/faq_med_eq.png"
import { Navigation } from "swiper";
const SliderFaq = () => {
  const FaqSwiper = [
    { id: "1", title: "Medical Equipment", image: faq_med_eq },
    { id: "2", title: "General Questions", image: faq_general_question },
    { id: "3", title: "Using Your Account", image: faq_using_your_accont },
    { id: "4", title: "Medical Equipment", image: faq_med_eq },
    { id: "5", title: "General Questions", image: faq_general_question },
  ];
  return (
    <Container>
      <div className="faqMain">
        <Swiper
          spaceBetween={70}
          slidesPerView={3}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },

            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {FaqSwiper.map((el, ind) => (
            <SwiperSlide key={ind}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                className="image_box"
              >
                <img src={el.image} />
              </Box>
              <Typography sx={{ fontSize: "20px" }}>{el.title}</Typography>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next">
          <KeyboardBackspaceIcon
            className="icon"
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
        <div className="swiper-button-prev">
          <KeyboardBackspaceIcon className="icon" />
        </div>
      </div>
    </Container>
  );
};

export default SliderFaq;
