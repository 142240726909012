import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "./nexus.scss";
import { Typography, Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ViewCard from "../../pages/viewCart/ViewCard";
import { useTranslation } from "react-i18next";
const Example = ({ products, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  console.log("products", products?.length);
  return (
    <div
      className="carousel-container"
      style={{ marginTop: "20px", color: "#494949" }}
    >
      <Box
        my={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"30px"} fontWeight={"700"}>
          {t("marketplace.latestProducts")}
        </Typography>
        <Button
          className="outlined-grey"
          variant="outlined"
          sx={{
            padding: { xs: "5px 12px !important", sm: "10px 28px !important" },
            fontSize: { xs: "12px !important", sm: "14px !important" },
          }}
          onClick={() => navigate("/productlisting")}
        >
          {t("marketplace.exploreAll")}
        </Button>
      </Box>

      {loading ? (
        <Box textAlign="center">
          <CircularProgress sx={{ color: "#35A6AE" }} />
        </Box>
      ) : products && products?.length > 0 ? (
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          autoplay={{ delay: 3000 }}
          navigation={true}
          modules={[Navigation]}
          rewind={true}
          loop={true}
          className="mySwiper"
          // breakpoints={{
          //   320: {
          //     slidesPerView: 1,
          //   },
          //   768: {
          //     slidesPerView: 2,
          //   },
          //   1024: {
          //     slidesPerView: 3,
          //   },
          //   1199: {
          //     slidesPerView: 4,
          //   },
          // }}
          breakpoints={{
            1199: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },

            0: {
              slidesPerView: 1,
            },
          }}
        >
          {products &&
            products?.map((product, index) => {
              return (
                <SwiperSlide key={index}>
                  <ViewCard el={product} />
                </SwiperSlide>
              );
            })}
        </Swiper>

      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          width="100%"
        >
          No Products are available!
        </Box>
      )}

      <br />
    </div>
  );
};

export default Example;
