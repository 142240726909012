import React from "react";
import "./authLayout.scss";
import { Box, Grid, Typography } from "@mui/material";
import main from "../../../assets/connectRxImages/AuthLeftImage.svg";
import capsule1 from "../../../assets/connectRxImages/capsule1.svg";
import capsule2 from "../../../assets/connectRxImages/capsule2.svg";
import logo from "../../../assets/images/connectLogo.png";
import { useLocation, useNavigate } from "react-router";
import LanguageToggle from "../../../../LanguageToggle";
import { useTranslation } from "react-i18next";
import SignUpImage from "../../../assets/connectRxImages/SingupImage.svg";
import BackArrowButton from "../backarrowbutton/BackArrowButton";
const AuthLayout = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signUpPage = location?.pathname?.includes("signup");
  const signInPage = location?.pathname?.includes("login");

  return (
    <Box>
      {/* <Box textAlign="right">
        <LanguageToggle className={"language-toggle"} langColor="#00000" />
      </Box> */}
      <BackArrowButton route={signInPage ? "/" : null || signUpPage ? "/" : null} />
      <Box
        className="auth-container"
        backgroundColor="#35A6"
        width="100%"
        height="100%"
      >
        {signUpPage ? (
          <Box justifyContent="center" display="flex" alignItems=" center">
            <Grid container spacing={0}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box

                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "flex-start",
                    height: "100%",
                    padding: {
                      xs: "10px 30px",
                      sm: "10px 30px",
                      md: "10px 30px",
                      lg: "10px 60px",
                      xl: "10px 60px",
                    }
                  }}
                >
                  {children}
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                textAlign="right"
                alignSelf="start"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <Box
                  className="loginImg"
                  padding={12}
                // sx={{ width: "76.8%", display: "flex" }}
                >
                  <img
                    src={SignUpImage}
                    alt="Main Image"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box justifyContent="center" display="flex" alignItems=" center">
            <Grid container spacing={0}>
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                textAlign="right"
                alignSelf="start"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <Box
                  className="loginImg"
                  sx={{ width: "76.8%", display: "flex" }}
                >
                  <img src={main} alt="Main Image" />
                </Box>
              </Grid>
              <Grid
                item
                md={8}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "white",
                  borderRadius: { xs: 0, md: "45px 0px 0px 45px" },
                  display: "flex",
                  justifyContent: "center",
                  margin: { xs: 0, md: "3px 0px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {children}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AuthLayout;
