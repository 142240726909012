import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";
import { getBlogDetail } from "../../../services/blog";
import { useSelector, useDispatch } from "react-redux";
import "./blog.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import BlogImage from "../../../assets/businessPage/blog.jpg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Blog = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [customLoading, setCustomLoading] = useState(true);

  const blogRes = useSelector((state) => state?.blog?.blogDetail?.response);
  const loading = useSelector((state) => state?.blog?.blogDetail?.loading);

  const [blogData, setBlogData] = useState({
    blogdetail: blogRes,
    count: 0,
  });

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    dispatch(
      getBlogDetail(slug, page, limit, function (res) {
        if (res) {
          setCustomLoading(false);
        }
      })
    );
  }, [count]);
  useEffect(() => {
    setBlogData({
      blogdetail: blogRes,
      count: 0,
    });
  }, [blogRes, count]);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="ConnectRxLandingPageContainer">
        <Box sx={{ background: "#35A6AE" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("blogs.blogDetail")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("blogs.blogDetailDescr")}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          {" "}
          <Typography
            color="#000"
            fontSize={"14px !important"}
            fontWeight={600}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => navigate(`/blogs`)}
          >
            -- back
          </Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              height: "20vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid mt={3}>
            <Grid container spacing={3} sx={{ position: "relative" }}>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={9}
                xl={9}
                sx={{ order: { xs: "1", sm: "1" } }}
              >
                <Box className="blogDetailCardMain">
                  <Box className="blogDetailCard">
                    <Box className="imageIcon">
                      {blogData?.blogdetail?.blog?.featured_image ? (
                        <img src={blogData?.blogdetail?.blog?.featured_image} />
                      ) : (
                        <img src={BlogImage} />
                      )}
                    </Box>

                    <Box className="blogText">
                      <Typography className="heading">
                        {blogData?.blogdetail?.blog?.title}
                      </Typography>

                      <Typography className="title">
                        {blogData?.blogdetail?.blog?.description}
                      </Typography>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: blogData?.blogdetail?.blog?.content,
                        }}
                      ></div>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={{
                  order: { xs: "2", sm: "2" },
                }}
              >
                <Box className="blogList">
                  <Typography className="listHeading">
                    {t("blogs.relatedBlogs")}
                  </Typography>
                  <ul>
                    {blogData?.blogdetail &&
                      blogData?.blogdetail?.relatedBlogs?.length > 0 &&
                      blogData?.blogdetail?.relatedBlogs?.map((item) => (
                        <li>
                          <Typography
                            className="listText"
                            onClick={() => (
                              navigate(`/blog/${item.slug}`),
                              setCount(count + 1)
                            )}
                          >
                            {item.title}
                          </Typography>
                        </li>
                      ))}
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Blog;
