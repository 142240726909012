import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ResponsiveAppBar from "./businessHeader";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useNavigate } from "react-router-dom";
import { redirectPath } from "../../helpers/redirectPath";
import { useSelector } from "react-redux";
import bulletsIcon from "../../assets/images/bulletStarIcon.svg";
import { Container } from "@mui/system";
import "./business.scss";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { useTranslation } from "react-i18next";
import BestPriceArrow from "../../assets/images/bestPriceArrow.svg";
import MedicineImage from "../../assets/images/MedicineImgae.png";
import ShippingIcon from "../../assets/connectRxImages/ShippingIcon.svg";
import MoneyIcon from "../../assets/connectRxImages/MoneyIcon.svg";
import CardIcon from "../../assets/connectRxImages/CardIcon.svg";
import SupportIcon from "../../assets/connectRxImages/SupportIcon.svg";
import MedicensImage from "../../assets/connectRxImages/MedicensImage.svg";
import MedicalPromise from "../../assets/connectRxImages/MedicalPromise.svg";
import HandeImg from "../../assets/connectRxImages/HandeImg.svg";
import "./Cusswiper.scss";
import backgroundHero from "../../assets/images/mainBg.svg";
import { useTheme } from "@mui/material/styles";
import Footer from "../../shared/components/footer";
import CoverflowSwiper from "./swiperCoverFlow/CoverflowSwiper";
const NexusBusiness = () => {
  const [selectedButton, setSelectedButton] = useState(0);
  const handleButtonClick = (index) => {
    setSelectedButton(index);
    const stickyElement = document.querySelector(`.sticky${index}`);
    if (stickyElement) {
      stickyElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const stickyElements = document.querySelectorAll("[class^='sticky']");
      stickyElements.forEach((element, index) => {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setSelectedButton(index);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const products = [
    {
      _id: "64e458d0a72f6ab5ab43ac5f",
      product_name: "RIO-BRONZE SPF 30 TOWELETTES",
      description:
        "RIO-BRONZE SPF 30 TOWELETTES. DIN : 02248248 Electronic product monograph is not available. Company: ROGER'S AGENCIES INCORPORATED 9 56 Pennysylvania Avenue",
      brand: "ROGERS AGENCIES INCORPORATED",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F61FCw37emdL._AC_UF1000%2C1000_QL80_.jpg-1692686527981?alt=media&token=9103d26a-95d5-4ad7-85f8-2d82e3a9e9bd",
      },
      store: [
        {
          _id: "64dd57abaea55fa8cd523586",
          store_name: "twasa",
          business: "64dd57a9aea55fa8cd523582",
          city: "Calgary",
          status: "approved",
          uuid: "Zxo7KmWdly",
        },
      ],
      PRODUCT_CATEGORIZATION: "CAT IV - SUNBURN PROTECTANTS",
      DRUG_IDENTIFICATION_NUMBER: "02248248",
      createdAt: "2023-08-22T06:42:24.104Z",
      updatedAt: "2023-08-22T06:42:24.104Z",
      total: 3271,
      price: 37,
      product: [
        {
          _id: "64e458d0a72f6ab5ab43ac64",
          product: "64e458d0a72f6ab5ab43ac5f",
          batch_number: "43093958238",
          store: "64dd57abaea55fa8cd523586",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 3271,
          expiry_date: "2025-12-31T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 37,
          faqs: [],
          DIN_NUMBER: "02248248",
          createdAt: "2023-08-22T06:42:24.429Z",
          updatedAt: "2023-09-13T15:55:57.715Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64e458d0a72f6ab5ab43ac64",
        product: "64e458d0a72f6ab5ab43ac5f",
        batch_number: "43093958238",
        store: "64dd57abaea55fa8cd523586",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 3271,
        expiry_date: "2025-12-31T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 37,
        faqs: [],
        DIN_NUMBER: "02248248",
        createdAt: "2023-08-22T06:42:24.429Z",
        updatedAt: "2023-09-13T15:55:57.715Z",
        __v: 0,
      },
    },
    {
      _id: "64e30f0f4483ce2e75d05e77",
      product_name: "TARO-CLOBETASOL CREAM USP",
      description:
        "Clobetasol is a topical (skin-applied) corticosteroid medication used to treat skin conditions that have not responded well to other corticosteroids, including moderate to severe psoriasis and eczematous dermatitis.",
      brand: "TARO PHARMACEUTICALS INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F1258010.png-1692602068208?alt=media&token=54158c21-a5e7-416f-89e3-37c0d4917461",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02245523",
      createdAt: "2023-08-21T07:15:27.392Z",
      updatedAt: "2023-08-21T13:48:09.199Z",
      total: 5438,
      price: 32,
      product: [
        {
          _id: "64e30f0f4483ce2e75d05e7c",
          product: "64e30f0f4483ce2e75d05e77",
          batch_number: "675432425235",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: true,
            discountsArray: [
              {
                month: 3,
                discount: 30,
                _id: "64e30f0f4483ce2e75d05e7d",
              },
            ],
            isManualDiscountApplied: false,
          },
          quantity: 5438,
          expiry_date: "2026-01-20T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 32,
          faqs: [
            {
              question: "Is clobetasol bad for skin?",
              answer:
                "You're more likely to have a serious side effect if you use clobetasol on a large area of skin for a long time. Clobetasol cream contains cetostearyl alcohol, which may cause a skin reaction in the area you are treating.",
              id: "X0MnkXeN",
            },
            {
              question: "How long to use taro clobetasol?",
              answer:
                "Do not use clobetasol for more than 4 weeks without talking to a doctor. If you need treatment for a long time, a doctor may recommend that you use a milder cream or ointment.",
              id: "9QY0O4VL",
            },
            {
              question: "Can I use Taro clobetasol on my face?",
              answer:
                "This medicine should not be used to treat certain kinds of skin infections or conditions, such as severe burns. Do not use this medicine on the face, groin, or underarms, or if you have skin thinning, unless directed to do so by your doctor.",
              id: "Lat7xFl2",
            },
          ],
          DIN_NUMBER: "02245523",
          createdAt: "2023-08-21T07:15:27.806Z",
          updatedAt: "2023-09-14T06:53:23.912Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64e30f0f4483ce2e75d05e7c",
        product: "64e30f0f4483ce2e75d05e77",
        batch_number: "675432425235",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: true,
          discountsArray: [
            {
              month: 3,
              discount: 30,
              _id: "64e30f0f4483ce2e75d05e7d",
            },
          ],
          isManualDiscountApplied: false,
        },
        quantity: 5438,
        expiry_date: "2026-01-20T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 32,
        faqs: [
          {
            question: "Is clobetasol bad for skin?",
            answer:
              "You're more likely to have a serious side effect if you use clobetasol on a large area of skin for a long time. Clobetasol cream contains cetostearyl alcohol, which may cause a skin reaction in the area you are treating.",
            id: "X0MnkXeN",
          },
          {
            question: "How long to use taro clobetasol?",
            answer:
              "Do not use clobetasol for more than 4 weeks without talking to a doctor. If you need treatment for a long time, a doctor may recommend that you use a milder cream or ointment.",
            id: "9QY0O4VL",
          },
          {
            question: "Can I use Taro clobetasol on my face?",
            answer:
              "This medicine should not be used to treat certain kinds of skin infections or conditions, such as severe burns. Do not use this medicine on the face, groin, or underarms, or if you have skin thinning, unless directed to do so by your doctor.",
            id: "Lat7xFl2",
          },
        ],
        DIN_NUMBER: "02245523",
        createdAt: "2023-08-21T07:15:27.806Z",
        updatedAt: "2023-09-14T06:53:23.912Z",
        __v: 0,
        discountedPrice: {
          discountedPrice: "32.00",
          discountPercentage: "0%",
        },
      },
    },
    {
      _id: "64db2fdcac242277fff21571",
      product_name: "APO-OMEPRAZOLE",
      description:
        "It slows or prevents the production of acid within the stomach. ... It is also used in combination with antibiotics to treat stomach ulcers caused by H. pylori",
      brand: "APOTEX INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F810251160.webp-1692086227076?alt=media&token=868b6b1b-50a1-4d0a-8a50-b314b050b415",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02245058",
      createdAt: "2023-08-15T07:57:16.191Z",
      updatedAt: "2023-08-15T07:57:16.191Z",
      total: 842,
      price: 50,
      product: [
        {
          _id: "64db2fdcac242277fff21576",
          product: "64db2fdcac242277fff21571",
          batch_number: "345436457235",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 842,
          expiry_date: "2030-01-23T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 50,
          faqs: [],
          DIN_NUMBER: "02245058",
          createdAt: "2023-08-15T07:57:16.289Z",
          updatedAt: "2023-09-21T06:20:17.410Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2fdcac242277fff21576",
        product: "64db2fdcac242277fff21571",
        batch_number: "345436457235",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 842,
        expiry_date: "2030-01-23T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 50,
        faqs: [],
        DIN_NUMBER: "02245058",
        createdAt: "2023-08-15T07:57:16.289Z",
        updatedAt: "2023-09-21T06:20:17.410Z",
        __v: 0,
      },
    },
    {
      _id: "64db2f57ac242277fff21556",
      product_name: "VIAGRA",
      description:
        "Sildenafil is used to treat male sexual function problems (impotence or erectile dysfunction-ED). In combination with sexual stimulation, sildenafil works",
      brand: "UPJOHN CANADA ULC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2FVIAGRA-100MG-4S-600x600.jpg-1692086078926?alt=media&token=cff642a3-363a-4b24-a889-b3539c24161b",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02239766",
      createdAt: "2023-08-15T07:55:03.748Z",
      updatedAt: "2023-08-22T21:51:45.553Z",
      total: 2265,
      price: 42,
      product: [
        {
          _id: "64db2f57ac242277fff2155b",
          product: "64db2f57ac242277fff21556",
          batch_number: "345346546362",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: true,
            discountsArray: [
              {
                month: 2,
                discount: 50,
                _id: "64e52e3d8a682f4b6c027b49",
              },
            ],
          },
          quantity: 2265,
          expiry_date: "2023-10-30T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 42,
          faqs: [],
          DIN_NUMBER: "02239766",
          createdAt: "2023-08-15T07:55:03.846Z",
          updatedAt: "2023-09-22T12:45:57.050Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2f57ac242277fff2155b",
        product: "64db2f57ac242277fff21556",
        batch_number: "345346546362",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: true,
          discountsArray: [
            {
              month: 2,
              discount: 50,
              _id: "64e52e3d8a682f4b6c027b49",
            },
          ],
        },
        quantity: 2265,
        expiry_date: "2023-10-30T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 42,
        faqs: [],
        DIN_NUMBER: "02239766",
        createdAt: "2023-08-15T07:55:03.846Z",
        updatedAt: "2023-09-22T12:45:57.050Z",
        __v: 0,
        discountedPrice: {
          discountedPrice: "21.00",
          discountPercentage: "50%",
        },
      },
    },
    {
      _id: "64db2eddac242277fff2153b",
      product_name: "SPECTAM WATER SOLUBLE CONC 500MG/GM",
      description:
        "Buy SPECTAM WATER SOLUBLE CONC 500MG/GM Powder For Solution by P.V.U., DIVISION OF Vu00c9TOQUINOL N.-A. INC.. Get quick delivery of medicines online from ",
      brand: "P.V.U.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fwater-soluble-cbd-500mg-800x800.pngw3.webp-1692085970653?alt=media&token=0f648d16-6f96-49b6-8408-dea29647f10d",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "00565873",
      createdAt: "2023-08-15T07:53:01.607Z",
      updatedAt: "2023-08-15T07:53:01.607Z",
      total: 34604,
      price: 55,
      product: [
        {
          _id: "64db2eddac242277fff21540",
          product: "64db2eddac242277fff2153b",
          batch_number: "65423456764",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 34604,
          expiry_date: "2026-08-18T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 55,
          faqs: [],
          DIN_NUMBER: "00565873",
          createdAt: "2023-08-15T07:53:01.705Z",
          updatedAt: "2023-09-22T19:03:54.470Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2eddac242277fff21540",
        product: "64db2eddac242277fff2153b",
        batch_number: "65423456764",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 34604,
        expiry_date: "2026-08-18T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 55,
        faqs: [],
        DIN_NUMBER: "00565873",
        createdAt: "2023-08-15T07:53:01.705Z",
        updatedAt: "2023-09-22T19:03:54.470Z",
        __v: 0,
      },
    },
    {
      _id: "64db2e5fac242277fff21515",
      product_name: "VITAMIN C 500MG WITH NON-MEDICINAL ECHINACEA",
      description:
        "Vitamins & minerals are essential nutrients that perform hundreds of important roles in the body. From boosting immunity to regulating metabolism,",
      brand: "HOLISTA HEALTH (CANADA) INC.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F2157816-The-7-Best-Vitamins-and-Supplements-for-Stress-According-to-Dietitians-1296x728-Header-81b9bf.webp-1692085841064?alt=media&token=58147049-d029-418f-84c3-ca182d0f845a",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "LS - VITAMIN SUPPLEMENTS",
      DRUG_IDENTIFICATION_NUMBER: "02231941",
      createdAt: "2023-08-15T07:50:55.483Z",
      updatedAt: "2023-08-15T07:50:55.483Z",
      total: 7552,
      price: 87,
      product: [
        {
          _id: "64db2e5fac242277fff2151a",
          product: "64db2e5fac242277fff21515",
          batch_number: "3453453623424",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 7552,
          expiry_date: "2026-01-28T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 87,
          faqs: [],
          DIN_NUMBER: "02231941",
          createdAt: "2023-08-15T07:50:55.607Z",
          updatedAt: "2023-09-20T15:32:48.176Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2e5fac242277fff2151a",
        product: "64db2e5fac242277fff21515",
        batch_number: "3453453623424",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 7552,
        expiry_date: "2026-01-28T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 87,
        faqs: [],
        DIN_NUMBER: "02231941",
        createdAt: "2023-08-15T07:50:55.607Z",
        updatedAt: "2023-09-20T15:32:48.176Z",
        __v: 0,
      },
    },
    {
      _id: "64db2d6bac242277fff21478",
      product_name: "JOHNSON & JOHNSON ANTISEPTIC WIPE",
      description:
        "In my first aid kit I had a similar wipe made by Johnson & Johnson and it cleansed the wound beautifully without the stinging, painful agony of an alcohol wipe.",
      brand: "JOHNSON & JOHNSON INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F108690.jpg-1692085603090?alt=media&token=6aa7400b-a42b-4c94-b4a4-4671e1efd318",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "CAT IV",
      DRUG_IDENTIFICATION_NUMBER: "02231916",
      createdAt: "2023-08-15T07:46:51.232Z",
      updatedAt: "2023-08-21T13:47:47.545Z",
      total: 45615,
      price: 65,
      product: [
        {
          _id: "64db2d6bac242277fff2147d",
          product: "64db2d6bac242277fff21478",
          batch_number: "4364232353467",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 45615,
          expiry_date: "2049-01-20T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 65,
          faqs: [],
          DIN_NUMBER: "02231916",
          createdAt: "2023-08-15T07:46:51.331Z",
          updatedAt: "2023-09-21T12:52:52.416Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2d6bac242277fff2147d",
        product: "64db2d6bac242277fff21478",
        batch_number: "4364232353467",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 45615,
        expiry_date: "2049-01-20T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 65,
        faqs: [],
        DIN_NUMBER: "02231916",
        createdAt: "2023-08-15T07:46:51.331Z",
        updatedAt: "2023-09-21T12:52:52.416Z",
        __v: 0,
      },
    },
    {
      _id: "64db2d14ac242277fff2145d",
      product_name: "MAGNESIUM PHOSPHORICUM II SIMILIAPLEX",
      description:
        "Magnesium phosphoricum is a great anti-spasmodic remedy indicated in cramps, neuralgic pains, and radiating pains which are relieved by warmth.",
      brand: "SISU INC.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fbioplasgen-no-24.jpg-1692085513486?alt=media&token=20e996aa-bd27-49dc-ad30-bbb4a7505e66",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02231876",
      createdAt: "2023-08-15T07:45:24.598Z",
      updatedAt: "2023-08-15T07:45:24.598Z",
      total: 4330,
      price: 34,
      product: [
        {
          _id: "64db2d14ac242277fff21462",
          product: "64db2d14ac242277fff2145d",
          batch_number: "75645634523",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 4330,
          expiry_date: "2026-01-21T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 34,
          faqs: [],
          DIN_NUMBER: "02231876",
          createdAt: "2023-08-15T07:45:24.691Z",
          updatedAt: "2023-09-14T06:55:47.124Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2d14ac242277fff21462",
        product: "64db2d14ac242277fff2145d",
        batch_number: "75645634523",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 4330,
        expiry_date: "2026-01-21T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 34,
        faqs: [],
        DIN_NUMBER: "02231876",
        createdAt: "2023-08-15T07:45:24.691Z",
        updatedAt: "2023-09-14T06:55:47.124Z",
        __v: 0,
      },
    },
    {
      _id: "64db2cabac242277fff21442",
      product_name: "ORAP TAB 10MG",
      description:
        "Orap 10mg Tablet is used in the treatment of schizophrenia. It works by altering the thoughts and elevating the mood, thereby improving the person's ability to think, feel, and behave.",
      brand: "MCNEIL PHARMACEUTICAL",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fv44a2yt9ee14qomt7fiu.jpg-1692085400312?alt=media&token=886fe615-9475-4c89-8b8a-6eec51125961",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "00573817",
      createdAt: "2023-08-15T07:43:39.208Z",
      updatedAt: "2023-08-15T07:43:39.208Z",
      total: 3413,
      price: 56,
      product: [
        {
          _id: "64db2cabac242277fff21447",
          product: "64db2cabac242277fff21442",
          batch_number: "45345212435",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 3413,
          expiry_date: "2049-01-13T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 56,
          faqs: [],
          DIN_NUMBER: "00573817",
          createdAt: "2023-08-15T07:43:39.307Z",
          updatedAt: "2023-09-14T06:55:47.124Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2cabac242277fff21447",
        product: "64db2cabac242277fff21442",
        batch_number: "45345212435",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 3413,
        expiry_date: "2049-01-13T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 56,
        faqs: [],
        DIN_NUMBER: "00573817",
        createdAt: "2023-08-15T07:43:39.307Z",
        updatedAt: "2023-09-14T06:55:47.124Z",
        __v: 0,
      },
    },
    {
      _id: "64db29e0ac242277fff21377",
      product_name: "NU-IPRATROPIUM PLASTIC AMPULES 250 MCG/ML",
      description:
        "Nu-ipratropium Plastic Ampules 250 Mcg/ml, Solution, 250 mcg / mL ... Ipratropium Bromide 0.02% Solution Each Box Contains Twenty-Five 2.5ml Vials, 45.86USD",
      brand: "NU-PHARM INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fc4-500x500.webp-1692084694138?alt=media&token=65c2e58d-56c7-439d-933c-956ebbf5c136",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02231785",
      createdAt: "2023-08-15T07:31:44.620Z",
      updatedAt: "2023-08-15T07:31:44.620Z",
      total: 5458,
      price: 24,
      product: [
        {
          _id: "64db29e0ac242277fff2137c",
          product: "64db29e0ac242277fff21377",
          batch_number: "346534643325",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 5458,
          expiry_date: "2034-01-03T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 24,
          faqs: [],
          DIN_NUMBER: "02231785",
          createdAt: "2023-08-15T07:31:44.735Z",
          updatedAt: "2023-09-21T22:18:35.783Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db29e0ac242277fff2137c",
        product: "64db29e0ac242277fff21377",
        batch_number: "346534643325",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 5458,
        expiry_date: "2034-01-03T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 24,
        faqs: [],
        DIN_NUMBER: "02231785",
        createdAt: "2023-08-15T07:31:44.735Z",
        updatedAt: "2023-09-21T22:18:35.783Z",
        __v: 0,
      },
    },
  ];
  const CardData = [
    {
      id: "1",
      title: "Free Shipping",
      description: "Free Delivery to Your Location",
      image: ShippingIcon,
      bgColor: "#35A6AE",
    },
    {
      id: "2",
      title: "Money Guarantee",
      image: MoneyIcon,
      description: "30 days back",
      bgColor: "#4CB050",
    },
    {
      id: "3",
      title: "Payment Method",
      image: CardIcon,
      description: "Secure System",
      bgColor: "#F1AE40",
    },
    {
      id: "4",
      title: "Online Support",
      image: SupportIcon,
      description: "24 hours on day",
      bgColor: "#191718",
    },
  ];
  const salesAndPurchasesBullets = [
    "landingPage.bp1",
    "landingPage.bp2",
    "landingPage.bp3",
    "landingPage.bp4",
    "landingPage.bp5",
  ];

  useEffect(() => {
    redirectPath(user, navigate);
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Grid
          container
          sx={{
            backgroundImage: `url(${backgroundHero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: {
              xs: "40px 20px 60px",
              md: "70px 60px 100px",
              lg: "70px 50px 100px",
            },
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            display={"flex"}
            gap={{ xs: "10px", sm: "15px", md: "20px" }}
            flexDirection={"column"}
          >
            {/* <Box>
              <Button
                className="contained contained-primary"
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  borderRadius: "30px",
                  width: { xs: "50%", sm: "20%", md: "23%", lg: "20%" },
                  textTransform: "capitalize",
                }}
              >
                Best Price
              </Button>
              <img
                src={BestPriceArrow}
                alt="BestPriceArrow"
                style={{
                  maxWidth: "52.443px",
                  minHeight: "36.473px",
                  position: "relative",
                  top: "4px",
                  left: "-15px",
                }}
              />
            </Box> */}
            <Typography
              fontSize={{ xs: 22, sm: 28, md: 36 }}
              color={"#191718"}
              fontWeight={"600"}
              sx={{
                "& span": {
                  color: "#35A6AE",
                  fontSize: { xs: "22px", sm: "28px", md: "36px" },
                },
              }}
            >
              Dedicated <span> B2B </span> Exchange Portal for Healthcare
              Experts
            </Typography>
            <Typography
              fontSize={{ xs: 13, sm: 15, md: 20 }}
              color={"#191718"}
              fontWeight={"400"}
            >
              ConnectRx: Empowering pharmacies to maximize profits through our
              ground breaking exchange platform for surplus medication and
              medical equipment.
            </Typography>
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
                gridGap: { xs: "10px", sm: "10px" },
              }}
            >
              <Button
                className="contained contained-primary"
                sx={{
                  padding: { sm: "6px 26px" },
                  fontSize: { xs: "12px", sm: "16px" },
                  borderRadius: "16px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#1c4445",
                    color: 'white'
                  },
                }}
                onClick={() => navigate("/signup")}
              >
                {t("landingPage.loginBtn")}
              </Button>
              <Button
                className="contained"
                sx={{
                  border: "1px solid black",
                  padding: { sm: "6px 26px" },
                  fontSize: { xs: "12px", sm: "16px" },
                  color: "#000000",
                  backgroundColor: "transparent",
                  borderRadius: "16px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#35A6AE",
                    color: 'white',
                    border: "1px solid #35A6AE",
                  },
                }}
                onClick={() => navigate("/qr/businesscard")}
              >
                Schedule a Meeting
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            sx={{ marginTop: { xs: "20px", sm: "20px", md: "0" } }}
          >
            <img src={MedicineImage} alt="BestPriceArrow" width="100%" />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{ xs: "0", sm: "3", md: 3, lg: 0 }}
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%" },
            margin: "-51px auto 0",
            padding: { xs: " 0 10px", sm: "0" },
          }}
        >
          <Box
            display={"flex"}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "unset",
            }}
            gap={{ xs: "10px", sm: "9px", md: "30px", lg: "0" }}
            width={"90%"}
            margin={{ md: "30px auto", lg: "-51px auto 0" }}
            flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
            flex={"1"}
          >
            {CardData.map((el, ind) => (
              <Grid
                item
                key={ind}
                display={"flex"}
                gap={{ xs: "10px", sm: "15px", md: "15px" }}
                position={"relative"}
                sx={{
                  flexBasis: {
                    xs: "calc(50% - 5px)",
                    sm: "24%",
                    md: "calc(25% - 8px)",
                  },
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    borderRight: ind !== 3 ? "1px solid #191718" : "none",
                    top: "0",
                    right: "12px",
                    height: "47px",
                    "@media (max-width:1200px)": {
                      borderRight: "none",
                    },
                  },
                }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    background: el.bgColor,
                    borderRadius: "50px",
                    flexShrink: "0",
                    height: { xs: "28px", sm: "30px", md: "43px" },
                    width: { xs: "28px", sm: "30px", md: "43px" },
                  }}
                >
                  <img
                    src={el.image}
                    alt={el.image}
                    width={"35px"}
                    height={"35px"}
                    className="responsive_image"
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    fontSize={{
                      xs: "11px",
                      sm: "12px",
                      md: "16px",
                      lg: "16px",
                    }}
                    color={"#191718"}
                    fontWeight={"600"}
                  >
                    {el.title}{" "}
                  </Typography>
                  <Typography
                    fontSize={{
                      xs: "10px",
                      sm: "12px",
                      md: "12px",
                      lg: "12px",
                    }}
                    color={"#191718"}
                    fontWeight={"400"}
                  >
                    {el.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Box>
        </Grid>
        <CoverflowSwiper />
        <Box className="wrapper">
          <Typography
            mt={3}
            fontSize="34px"
            fontWeight=" 600"
            textAlign="center"
          >
            B2B Platform
          </Typography>
          <Box className="py-4 lg:py-12 xl:py-32">
            <Box
              data-module-block-featured-features
              className="will-change-transform "
              data-block-featured-features-border-color="rgba(57, 25, 82, 1)"
            >
              <div className="container">
                <div className="sticky0">
                  <Box className="lg:container mb-4 lg:mb-7 ">
                    <Box>
                      <Box
                        className="flex overflow-x-auto gap-x-2.5 lg:justify-center fl scrollbar-hide"
                        justifyContent="center"
                        sx={{
                          "@media (max-width:768px)": {
                            flexDirection: "column",
                            gap: "15px",
                          },
                        }}
                      >
                        <button
                          className="ml-4 lg:ml-0  flex-shrink-0 button button-l button-rounded border-[0.0625rem] !px-3 lg:!px-5 focus:outline-none"
                          type="button"
                          data-block-featured-features="link"
                          onClick={() => handleButtonClick(1)}
                          style={{
                            border: "none",
                            background: selectedButton === 1 ? "#35A6AE" : "rgb(223 229 229)",
                            color: selectedButton === 1 ? "white" : "black"
                          }}
                        >
                          <span>REDEFINING MEDICAL COMMERCE</span>
                        </button>
                        <button
                          className="flex-shrink-0 button button-l button-rounded border-[0.0625rem] !px-3 lg:!px-5 focus:outline-none"
                          type="button"
                          data-block-featured-features="link"
                          onClick={() => handleButtonClick(2)}
                          style={{
                            border: "none",
                            background: selectedButton === 2 ? "#35A6AE" : "rgb(223 229 229)",
                            color: selectedButton === 2 ? "white" : "black"
                          }}
                        >
                          <span>PRIME MEDICAL MARKETPLACE</span>
                        </button>
                        <button
                          className="flex-shrink-0 button button-l button-rounded border-[0.0625rem] !px-3 lg:!px-5 focus:outline-none"
                          type="button"
                          data-block-featured-features="link"
                          onClick={() => handleButtonClick(3)}
                          style={{
                            border: "none",
                            background: selectedButton === 3 ? "#35A6AE" : "rgb(223 229 229)",
                            color: selectedButton === 3 ? "white" : "black"
                          }}
                        >
                          <span>NO COLD CALLING</span>
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div className="sticky1">
                  <Box
                    data-block-featured-features="container"
                    className="pointer-events-none h-full w-full"
                    sx={{
                      position: "sticky",
                      top: "10px",
                      zIndex: 100,
                    }}
                  >
                    <article
                      id="tease-feature-1"
                      data-block-featured-features="card"
                      data-size="l"
                      className="bg-gold text-currant overflow-hidden rounded-3xl lg:rounded-5xl xl:rounded-9xl pointer-events-none pointer-events-auto w-full h-full"

                    >
                      <Box className="flex flex-col lg:flex-row relative z-2 h-full gap-y-4 lg:gap-y-0 lg:gap-x-11 xl:gap-x-0 lg:items-center">
                        <Box
                          className="w-full"
                          backgroundColor="black"
                          height="100%"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={{ xs: "0", sm: "0", md: "45px", lg: "45px", xl: "45px" }}
                          >
                            <Grid item xs={12} md={6} padding={4}>
                              <Typography
                                lineHeight="32px"
                                color="#FFF"
                                fontSize="26px"
                                fontWeight="700"
                                textTransform="capitalize"
                              >
                                ConnectRx is Pioneering the Revolution of
                                Medical Commerce
                              </Typography>
                              <Typography
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="400"
                                textTransform="capitalize"
                                margin="24px 0px"
                              >
                                ConnectRx stands as a pioneering B2B exchange
                                platform, exclusively uniting Regulated Medical
                                Professionals. ConnectRx utilizes a real-time
                                payment gateway called IntraPay, Where members
                                can seamlessly transact using their credit
                                cards, ensuring instantaneous transactions
                                directly between buyer and seller.
                              </Typography>
                              <Button
                                variant="contained"
                                onClick={() => navigate("/about-us")}
                                sx={{
                                  padding: "8px 20px !important",
                                  background: "#35A6AE",
                                  color: "white",
                                  border: "1px solid #fff",
                                  "&:hover": {
                                    background: "#1c4445",
                                    color: 'white'
                                  },
                                  boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "10px !important",
                                  textTransform: "capitalize",
                                }}
                              >
                                Learn More
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <img
                                src={HandeImg}
                                alt="HandeImg"
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </article>
                  </Box>
                </div>
                <div className="sticky2">
                  <Box
                    data-block-featured-features="container"
                    className="pointer-events-none h-full w-full absolute inset-0"
                    sx={{
                      top: "20px",
                      zIndex: 200,
                    }}
                  >
                    <article
                      id="tease-feature-2"
                      data-block-featured-features="card"
                      data-size="l"
                      className="bg-gold text-currant overflow-hidden rounded-3xl lg:rounded-5xl xl:rounded-9xl pointer-events-none pointer-events-auto w-full h-full"
                    >
                      <Box className="flex flex-col lg:flex-row relative z-2 h-full gap-y-4 lg:gap-y-0 lg:gap-x-11 xl:gap-x-0 lg:items-center">
                        <Box
                          className="w-full"
                          backgroundColor="#4CAF50"
                          height="100%"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={{ xs: "0", sm: "0", md: "45px", lg: "45px", xl: "45px" }}
                          >
                            <Grid item xs={12} md={6} padding={4}>
                              <Typography
                                lineHeight="32px"
                                color="#FFF"
                                fontSize="26px"
                                fontWeight="700"
                                textTransform="capitalize"
                              >
                                ConnectRx is Pioneering the Revolution of
                                Medical Commerce
                              </Typography>
                              <Typography
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="400"
                                textTransform="capitalize"
                                margin="24px 0px"
                              >
                                ConnectRx stands as a pioneering B2B exchange
                                platform, exclusively uniting Regulated Medical
                                Professionals. ConnectRx utilizes a real-time
                                payment gateway called IntraPay, Where members
                                can seamlessly transact using their credit
                                cards, ensuring instantaneous transactions
                                directly between buyer and seller.
                              </Typography>
                              <Button
                                variant="contained"
                                onClick={() => navigate("/about-us")}
                                sx={{
                                  padding: "8px 20px !important",
                                  background: "#35A6AE",
                                  border: "1px solid #fff",
                                  "&:hover": {
                                    background: "#1c4445",
                                    color: 'white'
                                  },
                                  boxShadow:
                                    "0px 2px 7px 0px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "10px !important",
                                  textTransform: "capitalize",
                                }}
                              >
                                Learn More
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <img
                                src={HandeImg}
                                alt="HandeImg"
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </article>
                  </Box>
                </div>
                <div className="sticky3">
                  <Box
                    data-block-featured-features="container"
                    className="pointer-events-none h-full w-full absolute inset-0"
                    sx={{
                      top: "40px",
                      zIndex: 300,
                    }}
                  >
                    <article
                      id="tease-feature-3"
                      data-block-featured-features="card"
                      data-size="l"
                      className="bg-gold text-currant overflow-hidden rounded-3xl lg:rounded-5xl xl:rounded-9xl pointer-events-none pointer-events-auto w-full h-full"
                    >
                      <Box className="flex flex-col lg:flex-row relative z-2 h-full gap-y-4 lg:gap-y-0 lg:gap-x-11 xl:gap-x-0 lg:items-center">
                        <Box
                          className="w-full"
                          backgroundColor="#35A6AE"
                          height="100%"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={{ xs: "0", sm: "0", md: "45px", lg: "45px", xl: "45px" }}
                          >
                            <Grid item xs={12} md={6} padding={4}>
                              <Typography
                                lineHeight="32px"
                                color="#FFF"
                                fontSize="26px"
                                fontWeight="700"
                                textTransform="capitalize"
                              >
                                ConnectRx is Pioneering the Revolution of
                                Medical Commerce
                              </Typography>
                              <Typography
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="400"
                                textTransform="capitalize"
                                margin="24px 0px"
                              >
                                ConnectRx stands as a pioneering B2B exchange
                                platform, exclusively uniting Regulated Medical
                                Professionals. ConnectRx utilizes a real-time
                                payment gateway called IntraPay, Where members
                                can seamlessly transact using their credit
                                cards, ensuring instantaneous transactions
                                directly between buyer and seller.
                              </Typography>
                              <Button
                                variant="contained"
                                onClick={() => navigate("/about-us")}
                                sx={{
                                  padding: "8px 20px !important",
                                  background: "#35A6AE",
                                  border: "1px solid #fff",
                                  "&:hover": {
                                    background: "#1c4445",
                                    color: 'white'
                                  },
                                  boxShadow:
                                    "0px 2px 7px 0px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "10px !important",
                                  textTransform: "capitalize",
                                }}
                              >
                                Learn More
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <img
                                src={HandeImg}
                                alt="HandeImg"
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </article>
                  </Box>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          width={{ xs: "100%", sm: "80%", md: "70%" }}
          sx={{ margin: { xs: "50px auto 20px", md: "80px auto 30px" } }}
        >
          <Typography
            fontSize="14px"
            fontFamily="Urbanist"
            fontWeight="700"
            textAlign="center"
          >
            Secure. Safe. Trusted.
          </Typography>
          <Typography
            color="#35A6AE"
            fontSize={{ xs: "20px", sm: "28px", md: "38px" }}
            fontFamily="Urbanist"
            fontWeight="600"
            maxWidth="800px"
            margin="auto"
            textAlign="center"
          >
            ConnectRx's Promise to Medical Professionals.
          </Typography>
        </Box>
        <Grid
          container
          alignItems="center"
          padding={{ xs: "0 10px", md: "0 70px" }}
          marginBottom={"50px"}
          spacing={{ xs: 5, md: 0 }}
        >
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "10px", md: "20px" }}
          >
            <Typography
              fontSize={{ xs: "20px", sm: "24px", md: "36px" }}
              fontWeight="700"
            >
              <span style={{ color: "#35A6AE" }}>Verified</span> Vigilance
            </Typography>
            <Typography variant="p" maxWidth="400px">
              At ConnectRx, we uphold the highest standards of verification to
              accessible to vetted medical professionals, guaranteeing a network
              where safety and trust are paramount. Your peace of mind is our
              priority, making every exchange worry-free.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/about-us")}
              sx={{
                textTransform: "capitalize",
                padding: {
                  xs: "5px 0px !important",
                  sm: "7px 0px !important",
                  md: "7px 0px !important",
                },
                background: "#35A6AE",
                fontSize: { xs: "14px", sm: "16px", md: "16px" },
                width: { xs: "55%", sm: "40%", md: "25%", lg: "20%" },
                borderRadius: "10px !important",
                "&:hover": {
                  background: "#1c4445",
                  color: 'white'
                },
              }}
            >
              Learn More
            </Button>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <img src={MedicalPromise} alt="img_one" style={{ width: "100%" }} />
          </Grid>
        </Grid>
        <Box>
          <Typography
            fontSize={{ xs: "26px", sm: "30px", md: "40px" }}
            color={"#35A6AE"}
            fontWeight={"700"}
            sx={{
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Exclusive Exchange Platform
          </Typography>
          <Grid container alignItems={"center"}>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <img src={MedicensImage} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Typography
                color={"#191718"}
                fontSize={{ xs: "26px", sm: "30px", md: "30px", lg: "36px" }}
                fontWeight={"700"}
                sx={{ marginBottom: "20px" }}
              >
                In An Industry Where Time and Money are Crucial..{" "}
              </Typography>

              {salesAndPurchasesBullets?.map((el, index) => (
                <Box
                  display="flex"
                  alignItems={"flex-start"}
                  gap={"10px"}
                  sx={{ marginBottom: "20px" }}
                  key={index}
                >
                  <img src={bulletsIcon} style={{ paddingTop: "4px" }} />
                  <Typography
                    color={"#191718"}
                    fontSize={{
                      xs: "14px",
                      sm: "18px",
                      md: "15px",
                      lg: "18px",
                    }}
                    fontWeight={"400"}
                  >
                    {t(el)}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
        <NewsLetter />
      </Container>
      <Footer />
    </>
  );
};
export default NexusBusiness;