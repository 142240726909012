import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, FormControlLabel, Typography } from "@mui/material";
import "./index.scss";

export default function LanguageToggle({ className, langColor }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div
      style={{ paddingLeft: "20px !important", alignSelf: "center" }}
      className={`${className} ${currentLanguage === "fr" ? "active" : ""}`}
    >
      <FormControlLabel
        sx={{ margin: "0px !important" }}
        control={
          <Switch
            checked={currentLanguage === "fr"}
            onChange={() =>
              changeLanguage(currentLanguage === "fr" ? "en" : "fr")
            }
            name="languageToggle"
            sx={{ marginRight: "5px" }}
          />
        }
        label={
          currentLanguage === "fr" ? (
            <Typography
              color={langColor}
              margin={0}
              fontSize={{ xs: "12px", sm: "16px" }}
              fontWeight={{ xs: "500", sm: "500" }}
            >
              Français
            </Typography>
          ) : (
            <Typography
              color={langColor}
              margin={0}
              fontSize={{ xs: "12px", sm: "16px" }}
              fontWeight={{ xs: "500", sm: "400" }}
            >
              English
            </Typography>
          )
        }
      />
    </div>
  );
}
