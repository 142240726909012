import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./businessHeader";
import "./business.scss";
import HeaderImg from "../../assets/businessPage/Header.png";
import FooterImg from "../../assets/businessPage/Footer.png";
import { Button, Stack, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import logo from "../../assets/images/connectLogo.png";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Container, width } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Footer from "../../shared/components/footer";
import { useTranslation } from "react-i18next";
import Mobile from "../../assets/connectRxImages/privacyMobileImage.svg";
import privicypolicyBg from "../../assets/connectRxImages/Privicy_PolicyBg1.png";
import man from "../../assets/connectRxImages/PrivacyManImage.svg";
import Newsletter from "../../shared/components/footer/newsletter";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      {/* <BackArrowButton /> */}
      <Container>
        <Box
          className="privicy_policy"
        >
          <Grid
            container
            spacing={{ xs: "4", md: "0" }}
            alignItems={"normal"}
            padding={{ xs: "30px 30px", md: "30px 50px" }}
            sx={{
              backgroundImage: `url(${privicypolicyBg})`,
              backgroundRepeat: "no-repeat",
              marginTop: "30px",
              fontFamily: "'Urbanist', sans-serif",
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={7}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={{ xs: "11px", md: "20px" }}
              >
                <Typography
                  fontSize={{ xs: "28px", md: "28px" }}
                  color={"#fff"}
                  fontWeight={"600"}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", md: "18px" }}
                  color={"#fff"}
                  fontWeight={"400"}
                  maxWidth={"450.518px"}
                >
                  At connectRx, we are committed to protecting your privacy and
                  supporting a robust privacy regime. This Privacy Policy outlines
                  the types of Personal Information we collect, how we use it, and
                  the steps we take to safeguard it.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} sx={{
              marginLeft: { xs: "0", sm: "0", md: "-30px", lg: "-180px", xl: "-180px" },
              paddingBottom: "100px",
              marginTop: { xs: "0", sm: "0", md: "35px", lg: "35px", xl: "35px" },
            }}>
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "455px", lg: "600px", xl: "600px" }
                }}
              >
                <img
                  src={Mobile}
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                  }}

                />
              </Box>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "20px", md: "28px" }}
            marginTop={"30px"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
                maxWidth={"550px"}
              >
                How We Use Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"593.444px"}
              >
                We use your Personal Information to provide services, maintain our
                relationship with you, and comply with legal requirements. This
                includes using Personal Information to:
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
                maxWidth={"593.444px"}
              >
                How We Protect Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"631.592px"}
              >
                We take the security of your Personal Information seriously. We
                use technical and organizational measures such as encryption,
                access controls, and training to protect against unauthorized
                access, alteration, disclosure or destruction of your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Disclosure of Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"869.022px"}
              >
                We take the security of your Personal Information seriously. We
                use technical and organizational measures such as encryption,
                access controls, and training to protect against unauthorized
                access, alteration, disclosure or destruction of your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Cross-Border Transfer of Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"905.842px"}
              >
                As part of our operations, we may transfer Personal Information to
                our service providers outside of Canada. In such cases, we ensure
                there are appropriate safeguards in place to protect your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Data Retention
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880.377px"}
              >
                We retain your Personal Information for as long as necessary to
                fulfill the purposes for which we collected it or as required by
                applicable laws or regulations.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Rights of Data Subjects
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"868.963px"}
              >
                You have the right to request access to and rectification or
                erasure of your Personal Information, data portability,
                restriction of processing of your Personal Information, the right
                to object to processing, and the right to lodge a complaint with a
                supervisory authority.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Changes to Our Privacy Policy
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880px"}
              >
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated revision date. Please
                check this page regularly to ensure you are comfortable with any
                changes.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Your Consent
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880px"}
              >
                By using our platform, you consent to the processing of your
                Personal Information as described in this Privacy Policy.
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "20px", md: "28px" }}
            marginTop={"30px"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
                maxWidth={"550px"}
              >
                How We Use Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
              >
                We use your Personal Information to provide services, maintain our
                relationship with you, and comply with legal requirements. This
                includes using Personal Information to:
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
                maxWidth={"593.444px"}
              >
                How We Protect Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"631.592px"}
              >
                We take the security of your Personal Information seriously. We
                use technical and organizational measures such as encryption,
                access controls, and training to protect against unauthorized
                access, alteration, disclosure or destruction of your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Disclosure of Your Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"869.022px"}
              >
                We take the security of your Personal Information seriously. We
                use technical and organizational measures such as encryption,
                access controls, and training to protect against unauthorized
                access, alteration, disclosure or destruction of your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Cross-Border Transfer of Personal Information
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"905.842px"}
              >
                As part of our operations, we may transfer Personal Information to
                our service providers outside of Canada. In such cases, we ensure
                there are appropriate safeguards in place to protect your Personal
                Information.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Data Retention
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880.377px"}
              >
                We retain your Personal Information for as long as necessary to
                fulfill the purposes for which we collected it or as required by
                applicable laws or regulations.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Rights of Data Subjects
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"868.963px"}
              >
                You have the right to request access to and rectification or
                erasure of your Personal Information, data portability,
                restriction of processing of your Personal Information, the right
                to object to processing, and the right to lodge a complaint with a
                supervisory authority.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Changes to Our Privacy Policy
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880px"}
              >
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated revision date. Please
                check this page regularly to ensure you are comfortable with any
                changes.
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "15px", md: "28px" }}
            >
              <Typography
                fontSize={{ xs: "24px", md: "28px" }}
                fontWeight={"600"}
              >
                Your Consent
              </Typography>
              <Typography
                fontSize={{ xs: "18px", md: "20px" }}
                fontWeight={"400"}
                maxWidth={"880px"}
              >
                By using our platform, you consent to the processing of your
                Personal Information as described in this Privacy Policy.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={{ xs: "15px", md: "28px" }}
            padding={{ xs: "70px 0", md: "90px 0" }}
          >
            <Typography
              fontSize={{ xs: "24px", md: "28px" }}
              fontWeight={"600"}
              textAlign={{ xs: "left", md: "center" }}
              maxWidth={"604.827px"}
            >
              What Personal Information We Collect
            </Typography>
            <Typography
              fontSize={{ xs: "18px", md: "20px" }}
              fontWeight={"400"}
              textAlign={{ xs: "left", md: "center" }}
              maxWidth={"758px"}
            >
              Personal Information refers to information that identifies an
              individual. The Personal Information we collect includes, but is not
              limited to, your name, address, email address, phone number, payment
              card details, professional medical credentials, and other related
              information required to provide our services.
            </Typography>
          </Box>
          <Grid
            container
            alignItems={"center"}
            padding={{ xs: "20px", sm: "20px", md: "0px" }}
            sx={{
              borderRadius: "52px",
              background: "#EBEBEB",
              marginBottom: "60px",
            }}
          >
            <Grid item xs={12} sm={7} md={6} lg={7}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={{ xs: "13px", md: "20px" }}
                padding={{ xs: "30px 0px 67px 20px", md: "0px 0px 0px 40px" }}
              >
                <Typography
                  fontSize={{ xs: "24px", md: "28px" }}
                  fontWeight={"700"}
                >
                  Contact Us
                </Typography>
                <Typography
                  fontSize={{ xs: "18px", md: "20px" }}
                  fontWeight={"400"}
                  maxWidth={"684px"}
                >
                  If you have any questions about our Privacy Policy or data
                  practices, please reach out!
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/contact-us")}
                  sx={{
                    fontSize: { xs: "14px", lg: "16px" },
                    background: "#35A6AE",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                    width: { xs: "80%", sm: "50%", lg: "35%" },
                    "&:hover": {
                      background: "#1c4445",
                      color: 'white'
                    }
                  }}
                >
                  Send Us A Message
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={5}>
              <img src={man} alt="" style={{ width: "100%", height: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Newsletter />
      </Container>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
