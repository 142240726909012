import { createAction, handleActions } from "redux-actions";
import ActionTypes from "../../shared/constants/actionTypes";
import { toast } from "react-toastify";
import { _getBlog, _getBlogDetail } from "../../shared/httpService/api";

const initialState = {
  blogs: { loading: false, response: {}, hasError: false, error: {} },
  blogDetail: { loading: false, response: {}, hasError: false, error: {} },
};
// get blog
export const getBlogStart = createAction(ActionTypes.GET_BLOG);
export const getBlogSuccess = createAction(
  ActionTypes.GET_BLOG_SUCCESS,
  (response) => response
);
export const getBlogError = createAction(
  ActionTypes.GET_BLOG_ERROR,
  (error) => error
);

export const getBlog = (page, limit, callback) => (dispatch) => {
  dispatch(getBlogStart());
  return _getBlog(page, limit)
    .then((response) => {
      dispatch(getBlogSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getBlogError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

// get blog detail
export const getBlogDetailStart = createAction(ActionTypes.GET_BLOG_DETAIL);
export const getBlogDetailSuccess = createAction(
  ActionTypes.GET_BLOG_DETAIL_SUCCESS,
  (response) => response
);
export const getBlogDetailError = createAction(
  ActionTypes.GET_BLOG_DETAIL_ERROR,
  (error) => error
);

export const getBlogDetail = (id, page, limit, callback) => (dispatch) => {
  dispatch(getBlogDetailStart());
  return _getBlogDetail(id, page, limit)
    .then((response) => {
      dispatch(getBlogDetailSuccess(response));
      if (response) {
        callback(response);
      }
    })
    .catch((error) => {
      dispatch(getBlogDetailError(error));
      if (error && error?.error) {
        toast.error(error?.message);
      } else {
        toast.error("Something went wrong");
      }
    });
};

const reducer = handleActions(
  {
    [ActionTypes.GET_BLOG]: (state) => ({
      ...state,
      blogs: {
        ...state.blogs,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_BLOG_SUCCESS]: (state, action) => ({
      ...state,

      blogs: {
        ...state.blogs,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_BLOG_ERROR]: (state) => ({
      ...state,

      blogs: {
        ...state.blogs,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),
    // blog detail
    [ActionTypes.GET_BLOG_DETAIL]: (state) => ({
      ...state,
      blogDetail: {
        ...state.blogDetail,
        loading: true,
        hasError: false,
        error: {},
      },
    }),
    [ActionTypes.GET_BLOG_DETAIL_SUCCESS]: (state, action) => ({
      ...state,

      blogDetail: {
        ...state.blogDetail,
        loading: false,
        hasError: false,
        error: {},
        response: action.payload?.data,
      },
    }),
    [ActionTypes.GET_BLOG_DETAIL_ERROR]: (state) => ({
      ...state,

      blogDetail: {
        ...state.blogDetail,
        loading: false,
        hasError: false,
        error: {},
        response: {},
      },
    }),
  },
  initialState
);

export default reducer;
