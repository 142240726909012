import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  getLandingPageCategory,
  getPharmacyLandingProducts,
} from "../../services/products";
import { useDispatch, useSelector } from "react-redux";
import CategoryLists from "./categoriesList";
import bismol from "../../assets/images/categories/bismol.jpg";
import Hydrocortisone from "../../assets/images/categories/Hydrocortisone.jpg";
import LINDANE from "../../assets/images/categories/LINDANE.jpg";
import Ethylene from "../../assets/images/categories/Ethylene.jpg";
import wart from "../../assets/images/categories/wart.jpg";
import callus from "../../assets/images/categories/callus.jpg";
import feverfew from "../../assets/images/categories/feverfew.jpg";
import OstroVit from "../../assets/images/categories/OstroVit.jpg";
import greenOrganic from "../../assets/images/categories/greenOrganic.jpg";
import Sunburn from "../../assets/images/categories/Sunburn.jpg";
import caffeine from "../../assets/images/categories/caffeine.jpg";
import peppermint from "../../assets/images/categories/peppermint.jpg";
import chamomile from "../../assets/images/categories/chamomile.jpg";
import FLORAVIT from "../../assets/images/categories/FLORAVIT.jpg";
import echincea from "../../assets/images/categories/echincea.jpg";
import oral from "../../assets/images/categories/oral.jpg";
import moringa from "../../assets/images/categories/moringa.jpg";
import ubiq from "../../assets/images/categories/ubiq.jpg";
import vetriscience from "../../assets/images/categories/vetriscience.jpg";
import valerian from "../../assets/images/categories/valerian.jpg";
import secure from "../../assets/images/categories/secure.jpg";
import omega from "../../assets/images/categories/omega-3.jpg";
import hydrogen from "../../assets/images/categories/hydrogen-peroxide.jpg";
import factors from "../../assets/images/categories/factors.jpg";
import mouthwash from "../../assets/images/categories/mouthwash.jpg";
import ephedra from "../../assets/images/categories/ephedra.jpg";
import lens from "../../assets/images/categories/lens.jpg";
import antifungal from "../../assets/images/categories/antifungal.jpg";
import tylenol from "../../assets/images/categories/tylenol.jpg";
import aco from "../../assets/images/categories/aco.jpg";
import mineral from "../../assets/images/categories/mineral.jpg";
import bottle from "../../assets/images/categories/bottle.jpg";
import sideDashboard from "../../assets/images/sideDashboard.svg";
import lineIcon from "../../assets/images/lineIcon.svg";

const categories = [
  {
    title: "LS - LINDANE LOTION - SCABICIDE",
    imageCover: LINDANE,
  },
  {
    title: "narcotics",
    imageCover: caffeine,
  },
  {
    title: "LS - DECONGESTANT ORAL ADULT",
    imageCover:
      "https://cdn.sehat.com.pk/product_images/o/756/Picture-365__23848_zoom.jpg",
  },
  {
    title: "LS - HYDROCORTISONE",
    imageCover: Hydrocortisone,
  },
  {
    title: "LS - BISMUTH SUBSALICYLATE",
    imageCover: bismol,
  },
  {
    title: "LS - ETHYLENE OXIDE GASEOUS STERILANT",
    imageCover: Ethylene,
  },
  {
    title: "LS - WART REMOVERS",
    imageCover: wart,
  },
  {
    title: "LS - CORN AND CALLOUS REMOVERS",
    imageCover: callus,
  },
  {
    title: "LS - FEVERFEW LEAF",
    imageCover: feverfew,
  },
  {
    title: "CAT IV - DIETARY MIN/DIETARY VIT. SUPPLEMENTS",
    imageCover: OstroVit,
  },
  {
    title: "LS - VIT. SUPPL./MIN. SUPPL./UBIQUINONE",
    imageCover: greenOrganic,
  },
  {
    title: "CAT IV - MED. SKIN CARE PROD./SUNBURN PROTECTANTS",
    imageCover: Sunburn,
  },
  {
    title: "LS - CAFFEINE",
    imageCover: caffeine,
  },
  {
    title: "LS - PEPPERMINT",
    imageCover: peppermint,
  },
  {
    title: "LS - CHAMOMILE",
    imageCover: chamomile,
  },
  {
    title: "LS - INTESTINAL FLORA MODIFIERS",
    imageCover: FLORAVIT,
  },
  {
    title: "LS - ECHINACEA ROOT",
    imageCover: echincea,
  },
  {
    title: "LS - DENTAL AND ORAL CARE PRODUCTS FOR PROF. USE",
    imageCover: oral,
  },
  {
    title: "CAT IV - DIETARY MINERAL SUPPLEMENTS",
    imageCover: moringa,
  },
  {
    title: "LS - MIN. SUPPLEMENTS/UBIQUINONE",
    imageCover: ubiq,
  },
  {
    title: "CAT IV - DIETARY VITAMIN SUPPLEMENTS",
    imageCover: vetriscience,
  },
  {
    title: "LS - VALERIAN",
    imageCover: valerian,
  },
  {
    title: "CAT IV - FLUORIDE CONTAINING ANTI-CARIES PRODUCTS",
    imageCover: secure,
  },
  {
    title: "LS - VIT. SUPPLEMENTS/UBIQUINONE",
    imageCover: omega,
  },
  {
    title: "LS - PEROXIDE ORAL CARE PRODUCTS",
    imageCover: hydrogen,
  },
  {
    title: "LS - UBIQUINONE",
    imageCover: factors,
  },
  {
    title: "LS - FLUORIDE-CONT. TREAT. GELS & RINSES FOR CONS.",
    imageCover: mouthwash,
  },
  {
    title: "LS - EPHEDRA",
    imageCover: ephedra,
  },
  {
    title: "CAT IV - CONTACT LENS DISINFECTANTS",
    imageCover: lens,
  },
  {
    title: "LS - ANTIFUNGALS (TOPICAL)",
    imageCover: antifungal,
  },
  {
    title: "LS - COUNTERIRRITANTS",
    imageCover: tylenol,
  },
  {
    title: "LS - HOMEOPATHIC PREPARATIONS",
    imageCover: aco,
  },
  {
    title: "LS - MINERAL SUPPLEMENTS",
    imageCover: mineral,
  },
  {
    title: "LS - VITAMIN SUPPLEMENTS",
    imageCover: bottle,
  },
  {
    title: "LS - EPHEDRINE/PSEUDOEPHEDRINE",
    imageCover:
      "https://levelupchem.com/wp-content/uploads/2020/04/pseudoephedrine.jpg",
  },
  {
    title: "LS - VIT. SUPPLEMENTS/MIN SUPPLEMENTS",
    imageCover:
      "https://www.naturesaid.co.uk/images/complete-multi-vitamins-minerals-p164-1087_zoom.jpg",
  },
  {
    title: "LS - ANORECTAL DRUG PRODUCTS",
    imageCover: "https://pics.drugstore.com/prodimg/452023/900.jpg",
  },
  {
    title: "CAT IV - FIRST AID ANTISEPTICS",
    imageCover:
      "https://media.naheed.pk/catalog/product/cache/49dcd5d85f0fa4d590e132d0368d8132/1/0/1056146-1.jpg",
  },
  {
    title: "LS - TOPICAL NASAL DECONGESTANTS",
    imageCover:
      "https://cdn.sehat.com.pk/product_images/o/756/Picture-365__23848_zoom.jpg",
  },
  {
    title: "LS - LAXATIVES (STIMULANT)",
    imageCover:
      "https://media.istockphoto.com/id/459013763/photo/dulcolax-laxative-suppositories.jpg?s=612x612&w=0&k=20&c=h7aFT4CywQhlX9wUawSriJ38vj1ngEAhfY3iBUCF8H8=",
  },
  {
    title: "CAT IV - ANTIPERSPIRANTS",
    imageCover:
      "https://superhealth.com.pk/wp-content/uploads/2021/06/DOXORUBICIN-10MG-INJ.jpg",
  },
  {
    title: "CAT IV - TOILET BOWL DISINFECTANT CLEANERS",
    imageCover:
      "https://www.alclean.pk/wp-content/uploads/2020/06/TBC-250ML-300x300.jpg",
  },
  {
    title: "LS - LACTULOSE",
    imageCover:
      "https://5.imimg.com/data5/SELLER/Default/2022/6/NL/HP/LH/142642635/lactulose-sodium-1000x1000.jpg",
  },
  {
    title: "LS - ANTIFLATULANTS/ANTACIDS",
    imageCover:
      "https://3.imimg.com/data3/GU/HC/MY-11120663/flatuna-250x250.jpg",
  },
  {
    title: "LS - ANTHELMINTICS",
    imageCover:
      "https://img2.exportersindia.com/product_images/bc-full/2020/5/5707304/anthelmintic-drugs-1590403625-5450714.jpg",
  },
  {
    title: "LS - CYPROHEPTADINE",
    imageCover:
      "https://4.imimg.com/data4/BY/LS/MY-11068382/cyproheptadine-2mg-500x500.jpg",
  },
  {
    title: "LS - LAXATIVES (STOOL SOFTENER)",
    imageCover: "https://images.heb.com/is/image/HEBGrocery/001798178",
  },
  {
    title: "LS - ANTACID/BISMUTH SUBSALICYLATES",
    imageCover:
      "https://www.emeds.pk/upload/pro-imges/image-8/bismol-120ml-2.jpg",
  },
  {
    title: "LS - ANALGESICS/CAFFEINE",
    imageCover:
      "https://www.news-medical.net/image.axd?picture=2014%2F7%2FPharmacology-620x480.jpg",
  },
  {
    title: "LS - TOPICAL ANTIBIOTICS",
    imageCover:
      "https://img.medscapestatic.com/pi/features/drugdirectory/octupdate/ACT01791.jpg?output-quality=50",
  },
  {
    title: "LS - QUATERNARY AMMONIUM COMP. AGAINST HIV",
    imageCover:
      "https://5.imimg.com/data5/MB/UK/RQ/IOS-14393995/product-jpeg-500x500.png",
  },
  {
    title: "CAT IV - ATHLETES FOOT TREATMENTS",
    imageCover:
      "https://zoompharmacy.co.nz/wp-content/uploads/2023/03/9310160816627-0.jpeg",
  },
  {
    title: "LS - TRIETHANOLAMINE SALICYLATE (TROLAMINE)",
    imageCover:
      "	https://m.media-amazon.com/images/I/81KiSZEgxHL._AC_SX425_.jpg",
  },
  {
    title: "CAT IV - MEDICATED SKIN CARE PRODUCTS",
    imageCover:
      "https://sa1s3optim.patientpop.com/assets/images/provider/photos/2227348.png",
  },
  {
    title: "CAT IV - THROAT LOZENGES",
    imageCover: "https://pics.drugstore.com/prodimg/11230/900.jpg",
  },
  {
    title: "CAT IV - ACNE THERAPY",
    imageCover:
      "https://media.self.com/photos/61b7caef0a23fbf29073f7b2/1:1/w_1000,h_1000,c_limit/Differin%20Gel.jpg",
  },
  {
    title: "LS - SLEEP AIDS",
    imageCover:
      "https://images-na.ssl-images-amazon.com/images/I/61QBrb2MliL._AC_UL600_SR600,600_.jpg",
  },
  {
    title: "CAT IV",
    imageCover:
      "https://www.brookings.edu/wp-content/uploads/2020/06/global_belgium_medicine_blister_packs.jpg",
  },
  {
    title: "LS - LAXATIVES (GENERAL)",
    imageCover:
      "	https://onemg.gumlet.io/l_watermark_346,w_480,h_48…_480,c_fit,q_auto,f_auto/mfrn7kjuf53iridi0jv5.jpg",
  },
  {
    title: "LS - ANTIFLATULENTS",
    imageCover: "https://www.rxspark.com/drug-image/21777/Package/full/1911",
  },
  {
    title: "LS - ANTACID",
    imageCover:
      "https://5.imimg.com/data5/DF/HE/NN/SELLER-16645300/pantomore-ls-500x500.jpg",
  },
  {
    title: "LS - EXPECTORANT - ADULT",
    imageCover:
      "https://newassets.apollo247.com/pub/media/catalog/product/a/s/asc0042.jpg",
  },
  {
    title: "LS - ACETYLSALICYLIC LABELLING STANDARD",
    imageCover:
      "https://fda.report/DailyMed/de3e223f-8d22-4034-b049-983b4a60daf5/Aspirin_81.jpg",
  },
  {
    title: "LS - DIMENHYDRINATE",
    imageCover:
      "https://barakat-pharma.com/wp-content/uploads/2020/02/dimenhydrinate-en.jpg",
  },
  {
    title: "LS - COUGH AND COLD",
    imageCover:
      "https://newassets.apollo247.com/pub/media/catalog/product/c/h/che0311_1.jpg",
  },
  {
    title: "LS - ANALG (ACETAMINOPHEN LABELLING STANDARD)",
    imageCover:
      "https://dentagama.com/img/030617031730PillsCapsulesCapletsChewables.jpg",
  },
  {
    title: "CAT IV - ANTIDANDRUFF PRODUCTS",
    imageCover:
      "https://cdnprod.mafretailproxy.com/sys-master-root/h5d/h7c/10223891349534/45046_3.jpg_480Wx480H",
  },
  {
    title: "LS - ANALG. (ACETAMINOPHEN",
    imageCover:
      "https://m.media-amazon.com/images/I/71Gy7z9J0AL._AC_SX569_.jpg",
  },
  {
    title: "CAT IV - DIAPER RASH PRODUCTS",
    imageCover:
      "https://static-01.daraz.pk/p/1ff97dba086497e2f06233debf110387.jpg",
  },
  {
    title: "LS - TOPICAL - ANAESTHETIC/ANALGESIC/ANTIPRURITIC",
    imageCover:
      "https://www.intechopen.com/media/chapter/47578/media/image9.jpeg",
  },
  {
    title: "CAT IV - HARD SURFACE DISINFECTANTS",
    imageCover:
      "https://www.techtrondirect.co.uk/cdn/shop/products/TechtronDirectHardSurfaceDisinfectant5Litre_grande.png?v=1588840767",
  },
  {
    title: "CAT IV - ANTISEPTIC SKIN CLEANSERS",
    imageCover:
      "https://qualitychoice.com/cdn/shop/products/media_e74ae818-9161-4fa8-92b2-63e9e9d1f742.jpg?v=1642110614",
  },
  {
    title: "CAT IV - ORAL HEALTH PRODUCTS",
    imageCover:
      "https://static-01.daraz.pk/p/17963bf9b4158e45a7da0fcbd3601af5.jpg",
  },
  {
    title: "CAT IV - SUNBURN PROTECTANTS",
    imageCover:
      "https://www.health.com/thmb/fa3kmnaaD3Em5je_IaCDbfwPqfY=/1500x1500/smart/filters:no_upscale()/hawaiian-tropic-silk-hydration-moisturizing-sun-care-after-sun-lotion-5debc1ef1e3743338f4b99effb1c7660.jpg",
  },
  {
    title: "other",
    imageCover:
      "https://img.onmanorama.com/content/dam/mm/en/kerala/top-news/images/2022/8/9/pills.jpg",
  },
];

const CategoryListing = () => {
  const [proCategories, setProCategories] = useState([]);
  const [listBulleted, setListBulleted] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const categoriesLoading = useSelector(
    (state) => state?.product?.landingPageCategories?.loading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(getPharmacyLandingProducts());
    dispatch(
      getLandingPageCategory(function (response) {
        if (response?.data?.categories?.length > 0) {
          response.data.categories = response?.data?.categories?.map(
            (item) => ({
              ...categories.find((el) => el.title == item.title),
              ...item,
            })
          );
          setProCategories(response.data.categories);
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    if (innerWidth <= 900) {
      setListBulleted(false);
    }
    return () => {
      window.removeEventListener("resize", () =>
        setInnerWidth(window.innerWidth)
      );
    };
  });

  return (
    <Container
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Grid container spacing={1}>
        <Grid item md={12} lg={12} sm={12} xs={12}>
          {categoriesLoading ? (
            <Box
              sx={{
                display: "flex",
                margin: "5rem auto",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "#35A6AE" }} />
            </Box>
          ) : (
            <>
              <Box
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {innerWidth > 900 && (
                  <Box className="toggleActiveList">
                    <IconButton
                      className={!listBulleted ? "activeList" : "notActiveList"}
                      onClick={() => setListBulleted(false)}
                      disabled={!listBulleted ? true : false}
                    >
                      <img
                        src={sideDashboard}
                        style={{
                          width: "22px",
                          height: "22px",
                          filter: "brightness(50%) hue-rotate(87deg)",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      className={listBulleted ? "activeList" : "notActiveList"}
                      onClick={() => setListBulleted(true)}
                      disabled={listBulleted ? true : false}
                    >
                      <img
                        src={lineIcon}
                        style={{
                          width: "22px",
                          height: "22px",
                          filter: "brightness(50%) hue-rotate(87deg)",
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Grid container spacing={0}>
                {proCategories?.length > 0
                  ? proCategories?.map((item, index) => {
                      return (
                        <CategoryLists
                          key={index}
                          prod={item}
                          listBulleted={listBulleted}
                        />
                      );
                    })
                  : null}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
export default CategoryListing;
