import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const QRModal = ({ open, onClose, qrImage }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"> Scan QR Code</DialogTitle>
      <DialogContent dividers={"paper"}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontWeight: "600", fontSize: "16px" }}
            >
              Scan QR code with your google authenticator app
            </Typography>
            <Box sx={{ textAlign: "center", marginTop: "25px" }}>
              <img src={qrImage} width="200px" height="200px" />
            </Box>
            <List component="nav" aria-label="secondary mailbox folder">
              <ListItem>
                <ListItemAvatar>
                  <Avatar>1</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Install Authenticator App on your smart phone Android / IOS" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>2</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Open the Google Authenticator App and scan QR code" />
              </ListItem>
            </List>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{ color: "#35A6AE" }}
          size="large"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRModal;
