import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalize } from "../../../helpers/formatting";
import { formatNumberWithCommas } from "../../../helpers/getTotalValue";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const PreOrderDetail = ({
  pre,
  ind,
  loading,
  handlePreOrderSelect,
  handleProductClick,
  handleDeliveryClick,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [shipmentSelected, setShipmentSelected] = useState("normal");
  return (
    <Box position={"relative"} mb={3}>
      <Box className="store-checkout">
        <b>Store : </b>
        {capitalize(pre?.orderedBy?.store_name)} ,<b> Location : </b>
        {pre?.carts[0]?.products[0]?.for?.location}
      </Box>
      <Card
        sx={{
          borderRadius: "20px",
          boxShadow: "0px 84px 214px -80px rgba(0, 0, 0, 0.08)",
          border: "1px solid #F0F0F0",
        }}
      >
        <CardContent>
          <>
            {pre && pre?.carts && pre?.carts?.length ? (
              pre?.carts?.map((el, i) => (
                <>
                  {el && el?.products && el?.products?.length
                    ? el?.products?.map((item, index) => (
                        <>
                          <Grid item md={12} sm={12} lg={12} xs={12} pt={2}>
                            {index == 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box pt={2}>
                                  <Chip
                                    label={`From ${el?.orderedTo?.uuid}`}
                                    sx={{
                                      color: "#101828",
                                      backgroundColor: "#F3CA60",
                                      borderRadius: "8px",
                                    }}
                                    size={isMobile ? "small" : "medium"}
                                  />
                                </Box>

                                {i == 0 ? (
                                  <Box
                                    pt={{
                                      md: 2,
                                      lg: 2,
                                      xs: 1,
                                    }}
                                    pl={{ xl: 5 }}
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        sx={{
                                          margin: "0px",
                                        }}
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: "#35A6AE !important",
                                            }}
                                            checked={pre?.isChecked}
                                            disabled={loading}
                                            onChange={(e) =>
                                              handlePreOrderSelect(
                                                ind,
                                                e?.target?.checked
                                              )
                                            }
                                            size={isMobile ? "small" : "medium"}
                                          />
                                        }
                                        label={t("sideBar.select")}
                                      />
                                    </FormGroup>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            )}
                          </Grid>
                          <Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={{ width: "100%" }}
                            >
                              <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Grid container spacing={2} pt={3}>
                                  <Grid item md={2} lg={2} sm={2} xs={6}>
                                    {item?.product &&
                                    item?.product?.product?.imageCover
                                      ?.full_image &&
                                    item?.product?.product?.imageCover
                                      ?.full_image ? (
                                      <img
                                        src={
                                          item?.product?.product?.imageCover
                                            ?.full_image
                                        }
                                        style={{
                                          objectFit: "cover",
                                          width: "80px",
                                          height: "80px",
                                        }}
                                      />
                                    ) : (
                                      <Box
                                        className="cusProductName"
                                        sx={{
                                          width: "80px",
                                          height: "80px",
                                        }}
                                      >
                                        <Typography className="image-product-text">
                                          {item?.product?.product?.product_name}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Grid>
                                  <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <Box>
                                      <Tooltip
                                        title={
                                          item?.product?.product?.product_name
                                        }
                                      >
                                        <Typography
                                          mx={{
                                            lg: 1,
                                            md: 1,
                                            sm: 1,
                                          }}
                                          className="cart-text text-ellipses"
                                        >
                                          {item?.product?.product?.product_name}
                                        </Typography>
                                      </Tooltip>
                                      <Typography
                                        sx={{
                                          marginX: "10px",
                                        }}
                                        variant="subtitle1"
                                        className="cart-price"
                                      >
                                        {`$${formatNumberWithCommas(
                                          Number(
                                            item?.count *
                                              (item?.discountedPrice
                                                ? item?.discountedPrice
                                                    ?.discountedPrice
                                                : item?.price)
                                          ).toFixed(2)
                                        )}`}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <TextField
                                      className="counter"
                                      type="number"
                                      value={item?.count}
                                      defaultValue={item?.count}
                                      id="outlined-basic"
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item md={2} lg={2} sm={2} xs={6}>
                                    <Box
                                      pl={{ xs: 4 }}
                                      sx={{
                                        float: "right",
                                      }}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={loading}
                                              checked={item?.isChecked}
                                              sx={{
                                                color: "#35A6AE !important",
                                              }}
                                              onChange={(e) =>
                                                handleProductClick(
                                                  ind,
                                                  i,
                                                  index,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          }
                                          label=""
                                        />
                                      </FormGroup>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={12} lg={12} xs={12} sm={12}>
                                <Box pt={3}>
                                  <Divider
                                    sx={{
                                      borderColor: "#EDEDED !important",
                                      border: "1px solid #EDEDED !important",
                                      width: "100%",
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={12} lg={12} sm={12} xs={12}>
                                {el?.products?.length - 1 == index && (
                                  <Stack
                                    direction={{
                                      md: "row",
                                      lg: "row",
                                      sm: "column",
                                      xs: "column",
                                    }}
                                    sx={{
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                    my={5}
                                  >
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        name={
                                          el?.shipping?.selectedShipping
                                            ?.shippingType
                                        }
                                        value={
                                          el?.shipping?.selectedShipping
                                            ?.shippingType
                                        }
                                        onChange={(e) =>
                                          handleDeliveryClick(
                                            ind,
                                            i,
                                            index,
                                            e?.target?.value
                                          )
                                        }
                                        className="checkout-radio"
                                      >
                                        <Grid item md={5} lg={5} sm={5} xs={12}>
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                onChange={(e) =>
                                                  setShipmentSelected(
                                                    e.target.value
                                                  )
                                                }
                                                value="normal"
                                                checked={
                                                  el?.shipping?.selectedShipping
                                                    ?.shippingType === "normal"
                                                }
                                                sx={{
                                                  color: "#35A6AE",
                                                  "&.Mui-checked": {
                                                    color: "#35A6AE",
                                                  },
                                                  position: "absolute",
                                                  zIndex: "1",
                                                }}
                                                size={
                                                  isMobile ? "small" : "medium"
                                                }
                                              />
                                            }
                                            labelPlacement="start"
                                            label={
                                              <Box>
                                                <Chip
                                                  disabled={loading}
                                                  className="chip-checkout"
                                                  label={
                                                    <>
                                                      <Typography
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        fontSize={{
                                                          lg: 14,
                                                          md: 14,
                                                          sm: 12,
                                                          xs: 10,
                                                        }}
                                                        fontWeight={400}
                                                        color={"#101828"}
                                                      >
                                                        {t(
                                                          "ProductDetail.normalDelivery"
                                                        )}
                                                        <span
                                                          style={{
                                                            marginLeft: "5px",
                                                          }}
                                                        >
                                                          $
                                                          {
                                                            el?.shipping
                                                              ?.estimatedShipping
                                                              ?.normalPrice
                                                          }
                                                        </span>
                                                      </Typography>
                                                      <Typography
                                                        fontSize={{
                                                          lg: 14,
                                                          md: 14,
                                                          sm: 12,
                                                          xs: 10,
                                                        }}
                                                        fontWeight={400}
                                                        color={"#101828"}
                                                      >
                                                        {t(
                                                          "ProductDetail.receiveIndays"
                                                        )}
                                                      </Typography>
                                                    </>
                                                  }
                                                />
                                              </Box>
                                            }
                                            sx={{
                                              marginLeft: "0px",
                                            }}
                                          />
                                        </Grid>
                                        <>
                                          <Grid
                                            item
                                            md={2}
                                            lg={2}
                                            sm={2}
                                            xs={12}
                                          >
                                            <Box
                                              p={{
                                                lg: 4,
                                                md: 4,
                                                sm: 4,
                                                xs: 1,
                                              }}
                                            >
                                              <Typography
                                                fontSize={{
                                                  lg: 18,
                                                  md: 18,
                                                  sm: 14,
                                                  xs: 12,
                                                }}
                                                fontWeight={500}
                                                color={"#101828"}
                                              >
                                                {t("ProductDetail.or")}
                                              </Typography>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            md={5}
                                            lg={5}
                                            sm={5}
                                            xs={12}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Radio
                                                  onChange={(e) =>
                                                    setShipmentSelected(
                                                      e.target.value
                                                    )
                                                  }
                                                  value="urgent"
                                                  checked={
                                                    el?.shipping
                                                      ?.selectedShipping
                                                      ?.shippingType ===
                                                    "urgent"
                                                  }
                                                  sx={{
                                                    color: "#35A6AE",
                                                    "&.Mui-checked": {
                                                      color: "#35A6AE",
                                                    },
                                                    position: "absolute",
                                                    zIndex: "1",
                                                  }}
                                                  size={
                                                    isMobile
                                                      ? "small"
                                                      : "medium"
                                                  }
                                                />
                                              }
                                              labelPlacement="start"
                                              label={
                                                <Chip
                                                  disabled={loading}
                                                  className="chip-checkout"
                                                  label={
                                                    <>
                                                      <Typography
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        fontSize={{
                                                          lg: 14,
                                                          md: 14,
                                                          sm: 12,
                                                          xs: 10,
                                                        }}
                                                        color={"#101828"}
                                                        fontWeight={400}
                                                      >
                                                        {t(
                                                          "ProductDetail.expeditedDelivery"
                                                        )}
                                                        <span
                                                          style={{
                                                            marginLeft: "5px",
                                                          }}
                                                        >
                                                          $
                                                          {
                                                            el?.shipping
                                                              ?.estimatedShipping
                                                              ?.urgent_price
                                                          }
                                                        </span>
                                                      </Typography>
                                                      <Typography
                                                        fontSize={{
                                                          lg: 14,
                                                          md: 14,
                                                          sm: 12,
                                                          xs: 10,
                                                        }}
                                                        fontWeight={400}
                                                        color={"#101828"}
                                                      >
                                                        {t(
                                                          "ProductDetail.receiveIn2days"
                                                        )}
                                                      </Typography>
                                                    </>
                                                  }
                                                />
                                              }
                                              sx={{
                                                marginLeft: "0px",
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      </RadioGroup>
                                    </FormControl>
                                  </Stack>
                                )}
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                justifyContent={"flex-end"}
                              >
                                {el?.products?.length - 1 == index &&
                                el &&
                                el?.shipping &&
                                el?.shipping?.estimatedShipping ? (
                                  <>
                                    <Box className="shipping-box">
                                      <Box
                                        component="div"
                                        justifyContent="flex-end"
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          className="charges"
                                          sx={{
                                            flex: 1,
                                          }}
                                        >
                                          {t("ProductDetail.rideCharges")}:
                                        </Typography>
                                        <Typography
                                          className="charges-text"
                                          sx={{
                                            marginX: "10px",
                                          }}
                                        >
                                          $
                                          {
                                            el?.shipping?.selectedShipping
                                              ?.ride_charges
                                          }
                                        </Typography>
                                      </Box>

                                      <Box
                                        justifyContent="flex-end"
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          className="charges"
                                          sx={{
                                            flex: 1,
                                          }}
                                        >
                                          {t("EditProduct.tax")}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            marginX: "10px",
                                          }}
                                          className="charges-text"
                                        >
                                          ${el?.shipping?.selectedShipping?.tax}
                                        </Typography>
                                      </Box>
                                      <Box
                                        component="div"
                                        justifyContent="flex-end"
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          fontSize={14}
                                          className="charges"
                                          sx={{
                                            flex: 1,
                                          }}
                                        >
                                          {t("sideBar.shippingFee")}:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            marginX: "10px",
                                          }}
                                          className="charges-text"
                                        >
                                          $
                                          {formatNumberWithCommas(
                                            el?.shipping?.selectedShipping
                                              ?.total
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Box>
                          </Box>
                          {el.products.length - 1 == index && (
                            <Divider
                              sx={{
                                borderColor: "#ccc",
                                marginTop: "1.5rem",
                              }}
                            />
                          )}
                        </>
                      ))
                    : ""}
                </>
              ))
            ) : (
              <></>
            )}
          </>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PreOrderDetail;
