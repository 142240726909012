import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import coverflow1 from "../../../assets/connectRxImages/coverflow1.svg";
import coverflow2 from "../../../assets/connectRxImages/coverflow2.svg";
import coverflow3 from "../../../assets/connectRxImages/coverflow3.svg";
import coverflow4 from "../../../assets/connectRxImages/coverflow4.svg";
import { Typography } from "@mui/material";
import "./BussinessCoverflow.scss";
import { KeyboardBackspace } from "@mui/icons-material";

const CoverflowSwiper = () => {
  const CoverflowData = [
    {
      id: 1,
      title: "ORAP TAB 10MG",
      image: coverflow1,
      bgColor: "black",
    },
    {
      id: 2,
      title: "Rio-BRONZE SPF 30... ",
      image: coverflow2,
      bgColor: "yellow",
    },
    {
      id: 3,
      title: "Ipravent Respirator",
      image: coverflow3,
      bgColor: "pink",
    },
    {
      id: 4,
      title: "JOHNSON & JOHNSON..",
      image: coverflow4,
      bgColor: "green",
    },
    {
      id: 5,
      title: "MAGNESIUM PHOSPHO...",
      image: coverflow1,
      bgColor: "black",
    },
    {
      id: 6,
      title: "JOHNSON & JOHNSON..",
      image: coverflow4,
      bgColor: "green",
    },
    {
      id: 7,
      title: "JOHNSON & JOHNSON..",
      image: coverflow4,
      bgColor: "gray",
    },
  ];

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  let swiper;

  return (
    <div className="coverflow_class">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={true}
        spaceBetween={0}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        autoplay={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        rewind={true}
        loop={true}
        className="mySwiper"
        onSwiper={(s) => (swiper = s)}
      >
        {CoverflowData.map((el, ind) => (
          <SwiperSlide key={ind}>
            <img src={el.image} alt="" />
            <Typography fontSize={"18px"} fontWeight={"700"}>
              {el.title}
            </Typography>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev" onClick={goPrev}>
        <KeyboardBackspace/>
      </div>
      <div className="swiper-button-next" onClick={goNext}>
      <KeyboardBackspace style={{transform: "rotate(180deg)"}}/>
      </div>
    </div>
  );
};

export default CoverflowSwiper;
