import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";
import Pagination from "../../../shared/components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import "./blog.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import BlogImage from "../../../assets/businessPage/blog.jpg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";
import { getBlog } from "../../../services/blog";

const Blog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [customLoading, setCustomLoading] = useState(true);
  const [state, setState] = useState({
    orders: [],
    count: 0,
  });

  const blogRes = useSelector((state) => state?.blog?.blogs?.response);
  const loading = useSelector((state) => state?.blog?.blogs?.loading);
  const [blogData, setBlogData] = useState({
    blogs: blogRes,
    count: 0,
  });
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [count]);

  useEffect(() => {
    dispatch(
      getBlog(page, limit, function (res) {
        if (res) {
          setCustomLoading(false);
        }
      })
    );
  }, []);
  useEffect(() => {
    setBlogData({
      blogs: blogRes,
      count: 0,
    });
  }, [blogRes]);

  useEffect(() => {
    const count = blogRes?.count ? blogRes?.count : 0;
    const perPage = 10;
    const buttonsCount = Math.ceil(count / perPage);
    setState({
      ...state,
      orders: blogRes?.blogs && blogRes?.blogs?.length ? blogRes?.blogs : [],
      count: buttonsCount,
    });
  }, [blogRes]);

  const handlePageChange = (e, value) => {
    dispatch(getBlog(value, limit, function (res) {}));
    setPage(value);
    setCustomLoading(false);
    setCount((pre) => pre + 1);
  };
  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="ConnectRxLandingPageContainer">
        <Box sx={{ background: "#35A6AE" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("blogs.blog")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("blogs.descr")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={2}>
          <Box className="blogCardMain">
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              blogData?.blogs?.blogs?.map((item) => (
                <Box className="blogCard">
                  <Box className="imageIcon">
                    {item?.featured_image ? (
                      <img src={item?.featured_image} />
                    ) : (
                      <img src={BlogImage} />
                    )}
                  </Box>
                  <Box className="blogText">
                    <Typography className="heading">{item?.title}</Typography>
                    <Typography className="title">
                      {item?.description}
                    </Typography>

                    <Box className="cardBtn">
                      <Button
                        sx={{
                          backgroundColor: "white !important",
                          color: "#35A6AE",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        onClick={() => navigate(`/blog/${item.slug}`)}
                      >
                        {t("blogs.readMore")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))
            )}
            {}
          </Box>
        </Grid>
        <Stack spacing={2} sx={{ alignItems: "center", marginTop: "15px" }}>
          <Pagination
            totalCount={state?.count}
            page={page}
            onPageChange={handlePageChange}
          />
        </Stack>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Blog;
