import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./businessHeader";
import "./business.scss";
import HeaderImg from "../../assets/businessPage/Header1.png";
import FooterImg from "../../assets/businessPage/Footer.png";
import { Button, Stack, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import logo from "../../assets/images/connectLogo.png";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Footer from "../../shared/components/footer";
import { useTranslation } from "react-i18next";
import termAndServicesBg from "../../assets/connectRxImages/Term_and_ServicesBg.png";
import termAndServicesImage from "../../assets/connectRxImages/termAndServicesImage.svg";
import Newsletter from "../../shared/components/footer/newsletter";
import man from "../../assets/connectRxImages/PrivacyManImage.svg";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";

const TermAndConditions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      {/* <BackArrowButton /> */}
      <Container>
        <Box
          className="privicy_policy"
        >
          <Grid
            container
            gap={{ xs: "30px", sm: "0px", md: "0px" }}
            sx={{
              backgroundImage: `url(${termAndServicesBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              padding: {
                xs: "60px 20px 100px",
                md: "100px 60px 140px",
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={8}
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: "10px", md: "20px" }}
            >
              <Typography
                fontSize={{ xs: "28px", md: "40px", lg: "48px" }}
                fontWeight="700"
                color="#fff"
              >
                {t("termAndConditions.termAndConditions")}
              </Typography>
              <Typography
                fontSize={{ xs: "14px", md: "16px", lg: "18px" }}
                fontWeight="400"
                color="#fff"
                maxWidth={"531.609px"}
              // sx={{ width: { xs: "100%", md: "65%" }, margin: "auto" }}
              >
                {t("termAndConditions.subTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <img
                src={termAndServicesImage}
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </Grid>
          </Grid>

          <Stack
            spacing={6}
            className="termstyling"
            sx={{
              marginBottom: "30px",
            }}
          >
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.heading3")}
              </Typography>
              <Typography>{t("termAndConditions.desc3")}</Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.bulletpayment5")}
              </Typography>
              <Typography>{t("termAndConditions.bulletpayment6")}</Typography>
              <Typography>{t("termAndConditions.bulletpayment7")}</Typography>
              <Typography>{t("termAndConditions.bulletpayment8")}</Typography>
              <Typography>{t("termAndConditions.bulletpayment9")}</Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.heading8")}
              </Typography>
              <Typography>{t("termAndConditions.desc8")}</Typography>
              <Typography>{t("termAndConditions.bulletpayment4")}</Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.bulletpayment1")}
              </Typography>
              <Typography>{t("termAndConditions.bulletpayment2")}</Typography>
              <Typography>{t("termAndConditions.bulletpayment3")}</Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.heading7")}
              </Typography>
              <Typography>{t("termAndConditions.desc7")}</Typography>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" }}>
              <Typography variant="h3">
                {t("termAndConditions.heading9")}
              </Typography>
              <Typography>{t("termAndConditions.desc9")}</Typography>
            </Box>
          </Stack>
          <Grid
            container
            alignItems={"center"}
            padding={{ xs: "20px", sm: "20px", md: "0px" }}
            sx={{
              borderRadius: "52px",
              background: "#EBEBEB",
              marginBottom: "50px",
            }}
          >
            <Grid item xs={12} sm={7} md={6} lg={7}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={{ xs: "13px", md: "20px" }}
                padding={{ xs: "30px 0px 67px 20px", md: "0px 0px 0px 40px" }}
              >
                <Typography
                  fontSize={{ xs: "24px", md: "28px" }}
                  fontWeight={"700"}
                >
                  Contact Us
                </Typography>
                <Typography
                  fontSize={{ xs: "18px", md: "20px" }}
                  fontWeight={"400"}
                  maxWidth={"684px"}
                >
                  If you have any questions about our Privacy Policy or data
                  practices, please reach out!
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/contact-us")}
                  sx={{
                    fontSize: { xs: "14px", lg: "16px" },
                    background: "#35A6AE",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                    width: { xs: "90%", sm: "50%", lg: "35%" },
                    "&:hover": {
                      background: "#1c4445",
                      color: 'white'
                    }
                  }}
                >
                  Send Us A Message
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={5}>
              <img src={man} alt="" style={{ width: "100%", height: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Newsletter />
      </Container>
      <Footer />
    </>
  );
};
export default TermAndConditions;
