import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid, CircularProgress, Typography, Container } from "@mui/material";
import { getInventoryWishLists } from "../../services/products";
import { useDispatch } from "react-redux";
import WishlistCard from "./wishlistCard";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inventoryWishListsLoading, setinventoryWishListsLoading] =
    useState(false);
  const [wishlists, setWishlists] = useState([]);
  const { wishListCount, setWishListCount } = useContext(AuthContext);

  useEffect(() => {
    setinventoryWishListsLoading(true);
    dispatch(
      getInventoryWishLists(
        function (response) {
          setinventoryWishListsLoading(false);
          if (response?.data?.wishlist?.length > 0) {
            let tempWish = response?.data?.wishlist.map((el) => {
              return {
                ...el,
                product: { ...el.product, isMarkedFavourite: true },
              };
            });
            setWishlists(tempWish);
          }
        },
        function (err) {
          setinventoryWishListsLoading(false);
        }
      )
    );
  }, []);

  return (
    <Container
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Typography
        color={"#000000"}
        fontSize={{ lg: 30, md: 30, sm: 28, xs: 24 }}
        fontWeight={500}
        mb={2}
      >
        {t("ProductDetail.Wishlist")}
      </Typography>
      <Grid container spacing={3}>
        {inventoryWishListsLoading ? (
          <Box m="auto">
            <CircularProgress
              sx={{
                color: "#35A6AE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "300px",
              }}
            />
          </Box>
        ) : (
          <>
            {wishlists && wishlists && wishlists?.length > 0 ? (
              wishlists?.map((el, index) => {
                return (
                  el?.product?.isMarkedFavourite && (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <WishlistCard
                        i={index}
                        key={index}
                        el={el}
                        wishlists={wishlists}
                        setWishlists={setWishlists}
                      />
                    </Grid>
                  )
                );
              })
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
                width="100%"
              >
                No wishlist are available!
              </Box>
            )}
          </>
        )}
        {wishlists?.length > 0 && wishListCount == 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
            width="100%"
          >
            No wishlist are available!
          </Box>
        )}
      </Grid>
    </Container>
  );
};
export default Wishlist;
