import React from "react";
import AuthLayout from "../../shared/components/authLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, InputLabel, TextField } from "@mui/material";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getForgotPasswordDetails } from "../../services/BAuth";
import { ClipLoader } from "react-spinners";
import FErrorMessage from "../../shared/components/FErrorMessage";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.auth?.forgotPassword?.loading);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        dispatch(getForgotPasswordDetails(values));
      }}
      validationSchema={Schema}
    >
      {(props) => (
        <AuthLayout>
          <Box>
            <Box textAlign="center">
              <Typography
                variant="h4"
                fontSize={{ lg: 40, sm: 32, xs: 28 }}
                sx={{
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                {t("Auth.forgetPassword")}
              </Typography>
              <Typography
                fontSize={{ lg: 14, md: 14, sm: 14, xs: 11 }}
                sx={{
                  fontWeight: "400",
                  color: "#70747E",
                }}
              >
                {t("Auth.detail")}
              </Typography>
            </Box>
            <form onSubmit={props.handleSubmit}>
              <Box pt={3}>
                <InputLabel shrink>Email</InputLabel>
                <TextField
                  fullWidth
                  placeholder={t("newsLetter.enterEmail")}
                  name="email"
                  type="email"
                  className="authfield"
                  required
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.email}
                  error={props.touched.email && Boolean(props.errors.email)}
                />
                <FErrorMessage name="email" />
              </Box>

              <Button
                className="containedPrimaryAuth"
                variant="contained"
                disabled={loading}
                sx={{
                  marginTop: "16px",
                  borderRadius: "15px !important",
                  width: "100% !important",
                }}
                size="large"
                onClick={props.handleSubmit}
              >
                {loading ? (
                  <ClipLoader size={25} color="white" loading />
                ) : (
                  <>{t("Auth.resetPass")}</>
                )}
              </Button>

              <Button
                className="containedPrimaryWhite"
                variant="contained"
                sx={{
                  color: "#35A6AE !important",
                  marginTop: "16px",
                  borderRadius: "15px !important",
                  width: "100% !important",
                }}
                size="large"
                onClick={() => history("/login", { replace: true })}
              >
                {t("Auth.backToLogin")}
              </Button>
            </form>
          </Box>
        </AuthLayout>
      )}
    </Formik>
  );
};

export default ForgetPassword;
