import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";
import { Grid } from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import FaqResponsiveHeader from "../faqHeader";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import "./testimonials.scss";

const Testimonials = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="ConnectRxLandingPageContainer">
        <Box sx={{ background: "#35A6AE" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("testimonials.heading")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("testimonials.subDetail")}
            </Typography>
          </Box>
        </Box>
        <Grid justifyContent="center">
          <Box className="testimonialCardMain">
            <Box className="testimonialCard">
              <Box className="imageIcon">
                <Typography className="pharmacyName">PharmaCare</Typography>
              </Box>
              <Typography className="heading">Abdul Ali</Typography>
              <Typography className="rating">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Typography>
              <Typography className="quote">,,</Typography>
              <Typography className="title">
                {t("testimonials.cardDetail")}
              </Typography>
            </Box>
            <Box className="testimonialCard">
              <Box className="imageIcon">
                <Typography className="pharmacyName">PharmaCare</Typography>
              </Box>
              <Typography className="heading">Avan Austin</Typography>
              <Typography className="rating">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Typography>
              <Typography className="quote">,,</Typography>
              <Typography className="title">
                {t("testimonials.cardDetail")}
              </Typography>
            </Box>
            <Box className="testimonialCard">
              <Box className="imageIcon">
                <Typography className="pharmacyName">PharmaCare</Typography>
              </Box>
              <Typography className="heading">Elena Nilsson</Typography>
              <Typography className="rating">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Typography>
              <Typography className="quote">,,</Typography>
              <Typography className="title">
                {t("testimonials.cardDetail")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Testimonials;
