import React, { useState, useRef } from "react";
import { Stepper, Step, StepLabel, Container } from '@mui/material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    Button,
    TextField,
    IconButton,
    OutlinedInput,
    Card,
    CardContent,
} from "@mui/material";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytesResumable,
} from "firebase/storage";

import Autocomplete from "react-google-autocomplete";
import AuthLayout from "../../shared/components/authLayout";
import { InputAdornment } from "@mui/material";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import FErrorMessage from "../../shared/components/FErrorMessage";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./signUp.scss";
import { pharmacySignupDetails } from "../../services/auth";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import SignaturePad from "../../shared/components/signaturePad";
import SignaturePreview from "../../shared/components/signaturePreview";
import { removeImageFromFireBase } from "../../helpers/imageResizer";
import eye from "../../assets/images/autheye.svg";
import closeEye from "../../assets/images/Auth_Eye.png";
import { useTranslation } from "react-i18next";
import PreviewImage from "../../modules/adminDashboard/profileSettings/PreviewImage";
import { Clear } from "@mui/icons-material";
import upload from "../../assets/images/upload.svg";
import { resizeFile } from "../../helpers/imageResizer";
import { storage } from "../../firebase";
import ResponsiveAppBar from "../businessPage/businessHeader";
import { width } from "@mui/system";
import SignUpForm from "./storeSignup";

const Signup = () => {
    //Stepper
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    //End Stepper
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [countryData, setCountryData] = useState(null);
    const [checked, setChecked] = useState(false);
    const [showSigPad, setShowSigPad] = useState(false);
    const [storeCountryCode, setStoreCountryCode] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null,
    });
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [storeCoordinates, setStoreCoordinates] = useState({
        lat: null,
        lng: null,
    });
    const [store_country, setStoreCountry] = useState("");
    const [store_province, setStoreProvince] = useState("");
    const [store_city, setStoreCity] = useState("");
    const [store_postcode, setPostCode] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const restricteSaleImgRef = useRef(null);
    const loading = useSelector(
        (state) => state.auth.pharmacySignupDetails?.loading
    );
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const handleSelect = async (value) => {
        let country = {};
        let city = {};
        let state = {};
        let postalCode = {};

        for (let i = 0; i < value.address_components.length; i++) {
            if (value.address_components[i].types.includes("locality")) {
                city.long_name = value.address_components[i].long_name;
                city.short_name = value.address_components[i].short_name;
            } else if (
                value.address_components[i].types.includes(
                    "administrative_area_level_1"
                )
            ) {
                state.long_name = value.address_components[i].long_name;
                state.short_name = value.address_components[i].short_name;
            } else if (value.address_components[i].types.includes("country")) {
                country.long_name = value.address_components[i].long_name;
                country.short_name = value.address_components[i].short_name;
            } else if (value.address_components[i].types.includes("postal_code")) {
                postalCode.postcode = value.address_components[i].long_name;
            }
        }
        setCity(city.long_name);
        setCountry(country.long_name);
        setProvince(state.long_name);
        setZipCode(postalCode.postcode);
    };
    const handlePharmacySelect = async (value) => {
        let store_country = {};
        let store_city = {};
        let store_state = {};
        let store_postcode = {};
        for (let i = 0; i < value.address_components.length; i++) {
            if (value.address_components[i].types.includes("locality")) {
                store_city.long_name = value.address_components[i].long_name;
                store_city.short_name = value.address_components[i].short_name;
            } else if (
                value.address_components[i].types.includes(
                    "administrative_area_level_1"
                )
            ) {
                store_state.long_name = value.address_components[i].long_name;
                store_state.short_name = value.address_components[i].short_name;
            } else if (value.address_components[i].types.includes("country")) {
                store_country.long_name = value.address_components[i].long_name;
                store_country.short_name = value.address_components[i].short_name;
            } else if (value.address_components[i].types.includes("postal_code")) {
                store_postcode.postcode = value.address_components[i].long_name;
            }
        }
        setStoreCity(store_city.long_name);
        setStoreCountry(store_country.long_name);
        setStoreProvince(store_state.long_name);
        setPostCode(store_postcode.postcode);
    };
    const handleDefault = (e, props) => {
        const values = {
            ...props.values,
        };
        if (e) {
            values.store_name = values.business_name;
            values.store_owner = values.business_owner_name;
            values.store_mobile_no = values.mobile_no;
            values.store_landline_num = values.business_landline_num;
            values.store_fax_no = values.fax_no;
            values.store_location = values.location;
            setStoreCity(city);
            setStoreCountry(country);
            setStoreProvince(province);
            setPostCode(zipCode);
            setStoreCountryCode(countryData);
            setStoreCoordinates(coordinates);
        } else {
            values.store_name = "";
            values.store_owner = "";
            values.store_mobile_no = "";
            values.store_landline_num = "";
            values.store_fax_no = "";
            values.store_location = "";
        }
        props.setValues({
            ...values,
        });
        setChecked(e);
    };
    const store_types = [
        {
            name: "pharmacy",
            id: "pharmacy",
        },
        {
            name: "dentist_clinic",
            id: "dentist_clinic",
        },
    ];
    const handleProfession = (e, props) => {
        const value = e.target.checked;
        props.setFieldValue("is_pharmacist", value);

        if (props?.values?.signature && !value) {
            removeImageFromFireBase(props.values.signature);
            props.setFieldValue("signature", "");
            props.setFieldValue("license_number", "");
        }
    };
    const handleDelete = (props) => {
        if (props?.values?.signature) {
            removeImageFromFireBase(props.values.signature);
            props.setFieldValue("signature", "");
        }
    };
    const handleRestrictesItemSale = (e, props) => {
        const value = e.target.checked;
        props.setFieldValue("restricted_item_sales", value);

        if (props?.values?.narcotic_distribution_license && !value) {
            removeImageFromFireBase(props.values.narcotic_distribution_license);
            props.setFieldValue("narcotic_distribution_license", "");
        }
    };
    const handleImageUpload = async (file, fieldName, props, index) => {
        if (!file) return;

        setImageLoading(true);
        const image = await resizeFile(file, 500, 500);

        const storageRef = ref(
            storage,
            `pharmacy/narcitic_docs/${file.name}-${Date.now()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
                setImageLoading(false);
                setProgresspercent(0);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    props.setFieldValue("narcotic_distribution_license", downloadURL);
                    setImageLoading(false);
                    setProgresspercent(0);
                });
            }
        );
    };
    const handeRemoveRestictedImage = (props) => {
        debugger;
        if (props?.values?.narcotic_distribution_license) {
            removeImageFromFireBase(props?.values?.narcotic_distribution_license);
            props.setFieldValue("narcotic_distribution_license", "");
        }
    };
    return (
        <>
            <ResponsiveAppBar />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                    dispatch(
                        pharmacySignupDetails(
                            values,
                            coordinates,
                            country,
                            province,
                            city,
                            zipCode,
                            countryData,
                            storeCoordinates,
                            store_country,
                            store_province,
                            store_city,
                            store_postcode,
                            storeCountryCode,
                            navigate,
                            toast
                        )
                    );
                }}
                validationSchema={Schema}
            >
                {(props) => (
                    <AuthLayout>
                        <Box mt={1} className="signup-box">
                            {/* <Box>
                <Typography
                  className="auth-signup-heading"
                  color="#35A6AE !important"
                >
                  {t("Signup.businessSignup")}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px", // Adjust this value as needed
                      lg: "16px",
                      xl: "25px",
                    },
                    fontWeight: "400",
                    color: "#70747E",
                  }}
                >
                  {t("Signup.title")}
                </Typography>
              </Box> */}
                            <form onSubmit={props.handleSubmit}>
                                <Box>
                                    {/* <Box mb={2}>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontWeight: "500",
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px", // Adjust this value as needed
                          lg: "18px",
                          xl: "27px",
                        },
                      }}
                    >
                      {t("Signup.information")}
                    </Typography>
                  </Box> */}
                                    {activeStep === 0 && (
                                        <>
                                            <Box>
                                                <Typography
                                                    className="auth-signup-heading"
                                                    color="#35A6AE !important"
                                                >
                                                    {t("Signup.businessSignup")}
                                                </Typography>
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                    gutterBottom
                                                    sx={{
                                                        fontSize: {
                                                            xs: "16px",
                                                            sm: "16px",
                                                            md: "16px", // Adjust this value as needed
                                                            lg: "16px",
                                                            xl: "25px",
                                                        },
                                                        fontWeight: "400",
                                                        color: "#70747E",
                                                    }}
                                                >
                                                    {t("Signup.title")}
                                                </Typography>
                                            </Box>
                                            <Box mb={2}>
                                                <Typography
                                                    sx={{
                                                        color: "#101828",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            xs: "18px",
                                                            sm: "18px",
                                                            md: "18px", // Adjust this value as needed
                                                            lg: "18px",
                                                            xl: "27px",
                                                        },
                                                    }}
                                                >
                                                    {t("Signup.information")}
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink>{t("Signup.businessName")}</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.businessName")}
                                                        value={props.values.business_name}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="business_name"
                                                        error={
                                                            props.touched.business_name &&
                                                            Boolean(props.errors.business_name)
                                                        }
                                                        required
                                                    />
                                                    <FErrorMessage name="business_name" />
                                                </Grid>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink htmlFor="owner-input">
                                                        {t("Signup.businessOwnerName")}
                                                    </InputLabel>

                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.businessOwnerName")}
                                                        value={props.values.business_owner_name}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="business_owner_name"
                                                        error={
                                                            props.touched.business_owner_name &&
                                                            Boolean(props.errors.business_owner_name)
                                                        }
                                                        required
                                                    />
                                                    <FErrorMessage name="business_owner_name" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink htmlFor="email-input">
                                                        Email
                                                    </InputLabel>

                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder="Email"
                                                        value={props.values.email}
                                                        onBlur={props.handleBlur}
                                                        onChange={props.handleChange}
                                                        name="email"
                                                        error={
                                                            props.touched.email && Boolean(props.errors.email)
                                                        }
                                                        type="email"
                                                        required
                                                    />
                                                    <FErrorMessage name="email" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink htmlFor="address-input">
                                                        {t("Signup.address")}
                                                    </InputLabel>

                                                    <Autocomplete
                                                        apiKey="AIzaSyB16e8txgjkiAtEUxYTXlQGLVWw3pbSdHw"
                                                        className="authfield"
                                                        language="en"
                                                        options={{
                                                            types: "address",
                                                            // componentRestrictions: { country: "ca" },
                                                        }}
                                                        value={props.values.location}
                                                        onChange={(event, value) => {
                                                            props.setFieldValue("location", value);
                                                        }}
                                                        onBlur={props.handleBlur("location")}
                                                        libraries="places"
                                                        defaultValue={props?.values?.location}
                                                        onPlaceSelected={(place) => {
                                                            setCoordinates({
                                                                lat: place.geometry.location.lat(),
                                                                lng: place.geometry.location.lng(),
                                                            });
                                                            handleSelect(place);

                                                            props.setFieldValue(
                                                                "location",
                                                                place.formatted_address
                                                            );
                                                        }}
                                                        placeholder="Address"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            padding: "10px",
                                                            color: "#333",
                                                            outline: "none",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                    />
                                                    {props?.errors?.location && props?.touched?.location && (
                                                        <div style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                                                            <span>{props?.errors?.location}</span>
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>{t("Signup.phoneNumber")}</InputLabel>
                                                    <PhoneInput
                                                        international
                                                        className="authfield"
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="mobile_no"
                                                        autoComplete="mobile_no"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setCountryData(country);
                                                            props.setFieldValue("mobile_no", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props?.values?.mobile_no}
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Phone"
                                                                name="mobile_no"
                                                                value={props.values.mobile_no}
                                                                error={
                                                                    props.touched.mobile_no &&
                                                                    Boolean(props.errors.mobile_no)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="mobile_no" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>{t("Signup.landline")}</InputLabel>
                                                    <PhoneInput
                                                        international
                                                        className="authfield"
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="business_landline_num"
                                                        autoComplete="business_landline_num"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setCountryData(country);
                                                            props.setFieldValue("business_landline_num", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props?.values?.business_landline_num}
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Landline"
                                                                name="business_landline_num"
                                                                value={props.values.business_landline_num}
                                                                error={
                                                                    props.touched.business_landline_num &&
                                                                    Boolean(props.errors.business_landline_num)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="business_landline_num" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>{t("Signup.faxNumber")}</InputLabel>
                                                    <PhoneInput
                                                        international
                                                        className="authfield"
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="fax_no"
                                                        autoComplete="fax_no"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setCountryData(country);
                                                            props.setFieldValue("fax_no", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props?.values?.fax_no}
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Fax Number"
                                                                name="fax_no"
                                                                value={props.values.fax_no}
                                                                error={
                                                                    props.touched.fax_no &&
                                                                    Boolean(props.errors.fax_no)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="fax_no" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>{t("Signup.password")}</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.password")}
                                                        value={props.values.password}
                                                        type={showPassword ? "text" : "password"}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="password"
                                                        error={
                                                            props.touched.password &&
                                                            Boolean(props.errors.password)
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Box pr={2}>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? (
                                                                                <img src={eye} />
                                                                            ) : (
                                                                                <img src={closeEye} style={{ width: "25px" }} />
                                                                            )}
                                                                        </IconButton>
                                                                    </Box>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        required
                                                    />
                                                    <FErrorMessage name="password" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>
                                                        {t("Signup.confirmPassword")}
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.confirmPassword")}
                                                        value={props.values.confirmPassword}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="confirmPassword"
                                                        error={
                                                            props.touched.confirmPassword &&
                                                            Boolean(props.errors.confirmPassword)
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Box pr={2}>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowConfirmPassword}
                                                                            onMouseDown={handleMouseDownConfirmPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showConfirmPassword ? (
                                                                                <img src={eye} />
                                                                            ) : (
                                                                                <img src={closeEye} style={{ width: "25px" }} />
                                                                            )}
                                                                        </IconButton>
                                                                    </Box>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        required
                                                    />
                                                    <FErrorMessage name="confirmPassword" />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} alignSelf="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                chec
                                                                ked={checked}
                                                                onChange={(e) =>
                                                                    handleDefault(e.target.checked, props)
                                                                }
                                                                sx={{
                                                                    "&.Mui-checked": {
                                                                        color: "#35A6AE",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        // label={t("Signup.saveAsDefault")}
                                                        label={<span style={{ fontSize: "14px", color: "gray" }}>{t("Signup.saveAsDefault")}</span>}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                value={props?.values?.is_pharmacist}
                                                                name={"is_pharmacist"}
                                                                checked={props?.values?.is_pharmacist}
                                                                onChange={(e) => handleProfession(e, props)}
                                                                sx={{
                                                                    "&.Mui-checked": {
                                                                        color: "#35A6AE",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        // label={t("Signup.pharmacist")}
                                                        label={<span style={{ fontSize: "14px", color: "#000000" }}><b>{t("Signup.pharmacist")}</b></span>}
                                                    />
                                                </Grid>
                                                <Grid container spacing={2} justifyContent="center" alignItems="center">
                                                    {props?.values && props?.values?.is_pharmacist ? (
                                                        <Grid item sm={6} xs={12}>
                                                            <InputLabel shrink>
                                                                {t("Signup.licenseNumber")}
                                                            </InputLabel>
                                                            <TextField
                                                                fullWidth
                                                                className="authfield"
                                                                placeholder="Enter License Number"
                                                                value={props.values.license_number}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                name="license_number"
                                                                error={
                                                                    props.touched.license_number &&
                                                                    Boolean(props.errors.license_number)
                                                                }
                                                                required
                                                            />
                                                            <FErrorMessage name="license_number" />
                                                        </Grid>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {props?.values &&
                                                        props?.values?.is_pharmacist &&
                                                        !props?.values?.signature ? (
                                                        <Grid item sm={6} xs={12}
                                                            sx={{
                                                                marginTop: { xs: '20px', sm: '20px', md: '20px', lg: '25px', xl: "30px" },
                                                            }}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                className="outlined-primary"
                                                                style={{
                                                                    borderColor:
                                                                        props.errors.signature &&
                                                                        props.touched.signature &&
                                                                        "#D32F2F",
                                                                    fontSize: '16px',
                                                                }}
                                                                onClick={() => setShowSigPad(true)}
                                                                fullWidth
                                                                size="large"
                                                            >
                                                                {t("Signup.uploadSignatures")}
                                                            </Button>
                                                            <FErrorMessage name="signature" />
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            {props?.values && props?.values?.signature ? (
                                                                <Grid item xs={12}>
                                                                    <SignaturePreview
                                                                        delete={true}
                                                                        handleDelete={() => handleDelete(props)}
                                                                        url={props?.values?.signature}
                                                                    />
                                                                </Grid>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    )}
                                                </Grid>
                                                <SignaturePad
                                                    showSigPad={showSigPad}
                                                    setShowSigPad={setShowSigPad}
                                                    props={props}
                                                />
                                                <Grid container spacing={2}>
                                                    {/* <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={7}
                            mt={2}
                            alignSelf="center"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={props?.values?.restricted_item_sales}
                                  onChange={(e) => handleRestrictesItemSale(e, props)}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#35A6AE",
                                    },
                                  }}
                                />
                              }
                              // label={t("Signup.sellNarcotics")}
                              label={<span style={{ fontSize: "14px", color: "gray" }}>{t("Signup.saveAsDefault")}</span>}
                            />
                          </Grid> */}
                                                    {props?.values && props?.values?.restricted_item_sales ? (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            md={5}
                                                            lg={5}
                                                            margin={{ lg: "15px 0px", md: "15px 0px" }}
                                                        >
                                                            <Typography
                                                                variant="body"
                                                                fontSize={14}
                                                                fontWeight={500}
                                                                fontStyle={"normal"}
                                                                color={"#101828 !important"}
                                                                textAlign={"center"}
                                                            >
                                                                {t("sideBar.NLPicture")}
                                                            </Typography>
                                                            {props.values.narcotic_distribution_license ? (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            position: "relative",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <PreviewImage
                                                                            file={
                                                                                props?.values?.narcotic_distribution_license
                                                                            }
                                                                        />

                                                                        {!loading ? (
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handeRemoveRestictedImage(
                                                                                        props,
                                                                                        "narcotic_distribution_license",
                                                                                        "",
                                                                                        props
                                                                                    )
                                                                                }
                                                                                aria-label="delete picture"
                                                                                className="delete-picture"
                                                                            >
                                                                                <Clear />
                                                                            </IconButton>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <Card className="upload-image-card">
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Box>
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="upload picture"
                                                                                onClick={() =>
                                                                                    restricteSaleImgRef.current.click()
                                                                                }
                                                                            >
                                                                                {imageLoading ? (
                                                                                    <Box
                                                                                        sx={{
                                                                                            width: "55px",
                                                                                            height: "55px",
                                                                                        }}
                                                                                    >
                                                                                        <CircularProgressbar
                                                                                            value={progresspercent}
                                                                                            text={`${progresspercent}%`}
                                                                                            styles={buildStyles({
                                                                                                backgroundColor: "#35A6AE",
                                                                                                pathColor: `rgba(35,93,94,${progresspercent / 100
                                                                                                    })`,
                                                                                                textColor: "#35A6AE",
                                                                                                textSize: "20px",
                                                                                            })}
                                                                                        />
                                                                                    </Box>
                                                                                ) : (
                                                                                    <img src={upload} />
                                                                                )}
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                    <CardContent sx={{ padding: "10px 0px" }}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <Box>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#35A6AE",
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "700",
                                                                                    }}
                                                                                >
                                                                                    {t("Auth.clickToUpload")}
                                                                                </Typography>

                                                                                <Typography
                                                                                    pl={1}
                                                                                    sx={{
                                                                                        color: "#70747E",
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "400",
                                                                                    }}
                                                                                >
                                                                                    {t("Auth.dragAndDrop")}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography
                                                                                textAlign="center"
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontWeight: "400",
                                                                                    color: "#878B93",
                                                                                }}
                                                                            >
                                                                                {t("Auth.detail3")}
                                                                            </Typography>
                                                                        </Box>
                                                                    </CardContent>
                                                                </Card>
                                                            )}

                                                            <FErrorMessage name="narcotic_distribution_license" />
                                                            <input
                                                                hidden
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) =>
                                                                    handleImageUpload(
                                                                        e.target.files[0],
                                                                        "narcotic_distribution_license",
                                                                        props
                                                                    )
                                                                }
                                                                ref={restricteSaleImgRef}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Grid>
                                                <Button
                                                    className="containedPrimary"
                                                    variant="contained"
                                                    sx={{ marginTop: "16px", width: "100% " }}
                                                    onClick={handleNext}
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <ClipLoader size={25} color="white" loading />
                                                    ) : (
                                                        <>{t("Signup.continue")}</>
                                                    )}
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                    {/* this is store detail */}
                                    {activeStep === 1 && (
                                        <>
                                            <Box>
                                                <Typography
                                                    className="auth-signup-heading"
                                                    color="#35A6AE !important"
                                                >
                                                    {t("Signup.storeSignup")}
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Box className="txt-divider">
                                                        <Typography
                                                            variant="body"
                                                            sx={{
                                                                color: "#101828",
                                                                fontWeight: "500",
                                                                fontSize: {
                                                                    xs: "18px",
                                                                    sm: "18px",
                                                                    md: "18px", // Adjust this value as needed
                                                                    lg: "18px",
                                                                    xl: "27px",
                                                                },
                                                            }}
                                                        >
                                                            {t("Signup.storeDetails")}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink> {t("Signup.storeName")}</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.storeName")}
                                                        value={props.values.store_name}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="store_name"
                                                        error={
                                                            props.touched.store_name &&
                                                            Boolean(props.errors.store_name)
                                                        }
                                                        required
                                                    />
                                                    <FErrorMessage name="store_name" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>
                                                        {t("Signup.storeOwnerName")}
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.storeOwnerName")}
                                                        value={props.values.store_owner}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="store_owner"
                                                        error={
                                                            props.touched.store_owner &&
                                                            Boolean(props.errors.store_owner)
                                                        }
                                                        required
                                                    />
                                                    <FErrorMessage name="store_owner" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>
                                                        {t("Signup.licenseNumber")}
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder={t("Signup.licenseNumber")}
                                                        value={props.values.store_license_number}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="store_license_number"
                                                        error={
                                                            props.touched.store_license_number &&
                                                            Boolean(props.errors.store_license_number)
                                                        }
                                                    />
                                                    <FErrorMessage name="store_license_number" />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6} pt={2}>
                                                    <InputLabel shrink>
                                                        {t("Signup.selectStoreType")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="type"
                                                        displayEmpty
                                                        input={<OutlinedInput />}
                                                        className="membersSelect"
                                                        label={t("Signup.selectStoreType")}
                                                        value={props.values.type}
                                                        error={props.touched.type && Boolean(props.errors.type)}
                                                        required
                                                        style={{
                                                            color: "gray",
                                                        }}
                                                    >
                                                        <MenuItem disabled value="">
                                                            <>Store type</>
                                                        </MenuItem>
                                                        {store_types?.map((name, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={name?.name}
                                                                onClick={(e) =>
                                                                    props.setFieldValue("type", name?.name)
                                                                }
                                                            >
                                                                {name?.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FErrorMessage name="type" />
                                                </Grid>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink id="demo-simple-select-label">
                                                        {t("Signup.storeAddress")}
                                                    </InputLabel>
                                                    <Autocomplete
                                                        apiKey="AIzaSyDo5VoxC6mMlbcvlrGJzvWpr_4FxEk0jHE"
                                                        className="authfield"
                                                        language="en"
                                                        value={props.values.store_location}
                                                        options={{
                                                            types: "address",
                                                            componentRestrictions: { country: "ca" },
                                                        }}
                                                        libraries="places"
                                                        onChange={(event, value) => {
                                                            props.setFieldValue("store_location", value);
                                                        }}
                                                        onBlur={props.handleBlur("store_location")}
                                                        defaultValue={props?.values?.store_location}
                                                        onPlaceSelected={(place) => {
                                                            setStoreCoordinates({
                                                                lat: place.geometry.location.lat(),
                                                                lng: place.geometry.location.lng(),
                                                            });
                                                            handlePharmacySelect(place);
                                                            props.setFieldValue(
                                                                "store_location",
                                                                place.formatted_address
                                                            );
                                                        }}
                                                        placeholder="Store Address"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            padding: "10px",
                                                            color: "#333",
                                                            outline: "none",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                    />
                                                    {props?.errors?.store_location &&
                                                        props?.touched?.store_location && (
                                                            <div
                                                                style={{ color: "#d32f2f", fontSize: "0.75rem" }}
                                                            >
                                                                <span>{props?.errors?.store_location}</span>
                                                            </div>
                                                        )}
                                                </Grid>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink>{t("Signup.phoneNumber")}</InputLabel>
                                                    <PhoneInput
                                                        international
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="store_mobile_no"
                                                        autoComplete="store_mobile_no"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setStoreCountryCode(country);
                                                            props.setFieldValue("store_mobile_no", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={
                                                            checked
                                                                ? props?.values?.mobile_no
                                                                : props?.values?.store_mobile_no
                                                        }
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Phone"
                                                                className="authfield"
                                                                name="store_mobile_no"
                                                                value={props.values.store_mobile_no}
                                                                error={
                                                                    props.touched.store_mobile_no &&
                                                                    Boolean(props.errors.store_mobile_no)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="store_mobile_no" />
                                                </Grid>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink>
                                                        {t("Signup.storeLandline")}
                                                    </InputLabel>
                                                    <PhoneInput
                                                        international
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="store_landline_num"
                                                        autoComplete="store_landline_num"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setStoreCountryCode(country);
                                                            props.setFieldValue("store_landline_num", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={
                                                            checked
                                                                ? props?.values?.business_landline_num
                                                                : props?.values?.store_landline_num
                                                        }
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Store Landline"
                                                                className="authfield"
                                                                name="store_landline_num"
                                                                value={props.values.store_landline_num}
                                                                error={
                                                                    props.touched.store_landline_num &&
                                                                    Boolean(props.errors.store_landline_num)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="store_landline_num" />
                                                </Grid>
                                                <Grid item sm={6} xs={12} md={6} lg={6}>
                                                    <InputLabel shrink>{t("Signup.storeFaxNo")}</InputLabel>
                                                    <PhoneInput
                                                        international
                                                        country="ca"
                                                        preferredCountries={["ca"]}
                                                        excludeCountries={["us"]}
                                                        disableInitialCountryGuess={false}
                                                        name="store_fax_no"
                                                        autoComplete="store_fax_no"
                                                        variant="filled"
                                                        onChange={(phone, country) => {
                                                            setStoreCountryCode(country);
                                                            props.setFieldValue("store_fax_no", phone);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={
                                                            checked
                                                                ? props?.values?.fax_no
                                                                : props?.values?.store_fax_no
                                                        }
                                                        required
                                                        style={{
                                                            height: "45px",
                                                            color: "#333",
                                                            border: "1px solid #d0d5dd",
                                                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                            borderRadius: "8px",
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                            padding: "8px 0px 8px 60px",
                                                            width: "100%",
                                                        }}
                                                        buttonStyle={{
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            background: "none",
                                                        }}
                                                    >
                                                        {() => (
                                                            <TextField
                                                                fullWidth
                                                                label="Store Fax No"
                                                                className="authfield"
                                                                name="store_fax_no"
                                                                value={props.values.store_fax_no}
                                                                error={
                                                                    props.touched.store_fax_no &&
                                                                    Boolean(props.errors.store_fax_no)
                                                                }
                                                                required
                                                            />
                                                        )}
                                                    </PhoneInput>
                                                    <FErrorMessage name="store_fax_no" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>GST</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder="Enter GST Number"
                                                        value={props.values.GST_NO}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="GST_NO"
                                                        error={
                                                            props.touched.GST_NO && Boolean(props.errors.GST_NO)
                                                        }
                                                        required
                                                    />
                                                    <FErrorMessage name="GST_NO" />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel shrink>PST</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        className="authfield"
                                                        placeholder="Enter PST Number"
                                                        value={props.values.PST_NO}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        name="PST_NO"
                                                    />
                                                </Grid>
                                                {/* <SignaturePad
                          showSigPad={showSigPad}
                          setShowSigPad={setShowSigPad}
                          props={props}
                        /> */}
                                            </Grid>

                                            {/* <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={7}
                          mt={2}
                          alignSelf="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={props?.values?.restricted_item_sales}
                                onChange={(e) => handleRestrictesItemSale(e, props)}
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#35A6AE",
                                  },
                                }}
                              />
                            }
                            // label={t("Signup.sellNarcotics")}
                            label={<span style={{ fontSize: "14px", color: "gray" }}>{t("Signup.saveAsDefault")}</span>}
                          />
                        </Grid>
                        {props?.values && props?.values?.restricted_item_sales ? (
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            margin={{ lg: "15px 0px", md: "15px 0px" }}
                          >
                            <Typography
                              variant="body"
                              fontSize={14}
                              fontWeight={500}
                              fontStyle={"normal"}
                              color={"#101828 !important"}
                              textAlign={"center"}
                            >
                              {t("sideBar.NLPicture")}
                            </Typography>
                            {props.values.narcotic_distribution_license ? (
                              <>
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <PreviewImage
                                    file={
                                      props?.values?.narcotic_distribution_license
                                    }
                                  />

                                  {!loading ? (
                                    <IconButton
                                      onClick={() =>
                                        handeRemoveRestictedImage(
                                          props,
                                          "narcotic_distribution_license",
                                          "",
                                          props
                                        )
                                      }
                                      aria-label="delete picture"
                                      className="delete-picture"
                                    >
                                      <Clear />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </>
                            ) : (
                              <Card className="upload-image-card">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      onClick={() =>
                                        restricteSaleImgRef.current.click()
                                      }
                                    >
                                      {imageLoading ? (
                                        <Box
                                          sx={{
                                            width: "55px",
                                            height: "55px",
                                          }}
                                        >
                                          <CircularProgressbar
                                            value={progresspercent}
                                            text={`${progresspercent}%`}
                                            styles={buildStyles({
                                              backgroundColor: "#35A6AE",
                                              pathColor: `rgba(35,93,94,${progresspercent / 100
                                                })`,
                                              textColor: "#35A6AE",
                                              textSize: "20px",
                                            })}
                                          />
                                        </Box>
                                      ) : (
                                        <img src={upload} />
                                      )}
                                    </IconButton>
                                  </Box>
                                </Box>
                                <CardContent sx={{ padding: "10px 0px" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          color: "#35A6AE",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {t("Auth.clickToUpload")}
                                      </Typography>

                                      <Typography
                                        pl={1}
                                        sx={{
                                          color: "#70747E",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {t("Auth.dragAndDrop")}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box>
                                    <Typography
                                      textAlign="center"
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#878B93",
                                      }}
                                    >
                                      {t("Auth.detail3")}
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Card>
                            )}

                            <FErrorMessage name="narcotic_distribution_license" />
                            <input
                              hidden
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImageUpload(
                                  e.target.files[0],
                                  "narcotic_distribution_license",
                                  props
                                )
                              }
                              ref={restricteSaleImgRef}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid> */}
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={7}
                                                mt={2}
                                                alignSelf="center"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={props?.values?.restricted_item_sales}
                                                            onChange={(e) => handleRestrictesItemSale(e, props)}
                                                            sx={{
                                                                "&.Mui-checked": {
                                                                    color: "#35A6AE",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    // label={t("Signup.sellNarcotics")}
                                                    label={<span style={{ fontSize: "14px", color: "gray" }}>{t("Signup.saveAsDefault")}</span>}
                                                />
                                            </Grid>
                                            {/* <Button
                        className="containedPrimary"
                        variant="contained"
                        sx={{ marginTop: "16px", width: "50% " }}
                        // onClick={props.handleSubmit}
                        onClick={handleBack}
                        disabled={loading}
                      >
                        {loading ? (
                          <ClipLoader size={25} color="white" loading />
                        ) : (
                          <>{t("Signup.back")}</>
                        )}
                      </Button> */}
                                            <Button
                                                className="containedPrimary"
                                                variant="contained"
                                                sx={{ marginTop: "16px", width: "100% " }}
                                                onClick={props.handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <ClipLoader size={25} color="white" loading />
                                                ) : (
                                                    <>{t("Signup.createAccount")}</>
                                                )}
                                            </Button>
                                            <ToastContainer />
                                        </>
                                    )}
                                </Box>

                            </form>

                            <Box
                                pt={1}
                                mb={2}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: {
                                        xs: "16px",
                                        sm: "16px",
                                        md: "16px", // Adjust this value as needed
                                        lg: "16px",
                                        xl: "25px",
                                    },
                                }}
                            >
                                <Typography color="text.secondary" variant="body">
                                    {t("Signup.alreadyAccount")}
                                </Typography>
                                <Button
                                    variant="text"
                                    sx={{
                                        fontSize: {
                                            xs: "15px",
                                            sm: "16px",
                                            md: "16px", // Adjust this value as needed
                                            lg: "16px",
                                            xl: "25px",
                                        },
                                        fontWeight: "700",
                                        color: "#03AD54",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => navigate("/login", { replace: true })}
                                >
                                    {t("header.login")}
                                </Button>
                            </Box>
                        </Box>
                    </AuthLayout>
                )}
            </Formik>
        </>
    );
};

export default Signup;
