import React from "react";
import AuthLayout from "../../shared/components/authLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getResetPasswordDetails } from "../../services/BAuth";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.auth?.resetPassword?.loading);

  return (
    <AuthLayout>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(getResetPasswordDetails(values, id, navigate));
        }}
        validationSchema={Schema}
      >
        {(props) => (
          <Box
            padding={{ xs: "10px 20px", md: 0 }}
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              fontSize={{ xs: 25, sm: 32, md: 36, lg: 40 }}
              mb={3}
              fontWeight={700}
              color="#000000"
            >
              {t("Auth.resetPass")}
            </Typography>
            <form autoComplete="off" onSubmit={props.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>{t("Signup.password")}</InputLabel>
                  <TextField
                    fullWidth
                    placeholder={t("Signup.password")}
                    className="authfield"
                    value={props.values.password}
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="password"
                    error={
                      props.touched.password && Boolean(props.errors.password)
                    }
                    helperText={props.touched.password && props.errors.password}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink>{t("Signup.confirmPassword")}</InputLabel>
                  <TextField
                    fullWidth
                    className="authfield"
                    placeholder={t("Signup.confirmPassword")}
                    value={props.values.confirmPassword}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    name="confirmPassword"
                    error={
                      props.touched.confirmPassword &&
                      Boolean(props.errors.confirmPassword)
                    }
                    type="password"
                    helperText={
                      props.touched.confirmPassword &&
                      props.errors.confirmPassword
                    }
                    required
                  />
                </Grid>
              </Grid>

              <Box pt={2}>
                <Button
                  className="containedPrimaryAuth"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  onClick={props.handleSubmit}
                >
                  {loading ? (
                    <ClipLoader size={25} color="white" loading />
                  ) : (
                    <> {t("Auth.resetPass")}</>
                  )}
                </Button>
              </Box>
            </form>
            <Box
              pt={{ lg: 3, md: 3, sm: 2, xs: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="large"
                variant="contained"
                className="containedPrimaryWhite"
                onClick={() => navigate("/login", { replace: true })}
              >
                {t("Auth.backToLogin")}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ResetPassword;
