import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";
import { Grid } from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import WhyBanner from "../../../assets/businessPage/whybanner.png";
import WhyBanner2 from "../../../assets/businessPage/whybanner2.png";
import FaqResponsiveHeader from "../faqHeader";
import Icon1 from "../../../assets/businessPage/crossCirclefill.svg";
import { useTranslation } from "react-i18next";
import "./whyUs.scss";

const WhyUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="ConnectRxLandingPageContainer">
        <Box sx={{ background: "#35A6AE" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("WhyUs.whyUS")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("WhyUs.details")}
            </Typography>
          </Box>
        </Box>
        <Grid justifyContent="center">
          <Box className="whyUsCardMain">
            <Box className="whyUsCard order2">
              <Typography className="name">NINA PERRY</Typography>
              <Typography className="title"> {t("WhyUs.position")}</Typography>
              <Typography className="heading"> {t("WhyUs.whyUS")}</Typography>
              <Typography className="para">{t("WhyUs.paragraph1")}</Typography>
            </Box>
            <Box className="whyUsCard imgSec order1">
              <img src={WhyBanner} alt="" />
            </Box>
          </Box>
          <Box className="whyUsCardMain">
            <Box className="whyUsCard imgSec">
              <img src={WhyBanner2} alt="" />
            </Box>
            <Box className="whyUsCard">
              <Typography className="name">NINA PERRY</Typography>
              <Typography className="title"> {t("WhyUs.position")}</Typography>
              <Typography className="heading"> {t("WhyUs.whyUS")}</Typography>
              <Typography className="para">{t("WhyUs.paragraph1")}</Typography>
            </Box>
          </Box>
          <Box className="serviceMain">
            <Box className="serviceCard">
              <img src={Icon1} />
              <Typography className="heading">
                {t("WhyUs.serviceName")}
              </Typography>
              <Typography className="para">
                {t("WhyUs.serviceDetail")}
              </Typography>
            </Box>
            <Box className="serviceCard">
              <img src={Icon1} />
              <Typography className="heading">
                {t("WhyUs.serviceName")}
              </Typography>
              <Typography className="para">
                {t("WhyUs.serviceDetail")}
              </Typography>
            </Box>
            <Box className="serviceCard">
              <img src={Icon1} />
              <Typography className="heading">
                {t("WhyUs.serviceName")}
              </Typography>
              <Typography className="para">
                {t("WhyUs.serviceDetail")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default WhyUs;
