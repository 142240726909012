import Icon from "./assets/images/preOrderIcon.png";
import Pres from "./assets/images/rx-icon.png";
import sideDashboard from "./assets/images/sideDashboard.svg";
import sideStore from "./assets/images/sideStore.svg";
import sideMember from "./assets/images/sideMember.svg";
import sideSale from "./assets/images/sideSale.svg";
import sideProducts from "./assets/images/sideProducts.svg";
import sidePurchase from "./assets/images/sidePurchase.svg";
import sideProfile from "./assets/images/sideProfile.svg";

const sidnavAdmin = [
  {
    name: "sideBar.dashboard",
    file: "dashboard",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideDashboard} alt="sideDashboard" />,
    link_to: "/bus/dashboard",
  },
  {
    name: "sideBar.Stores",
    file: "stores",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideStore} alt="sideStore" />,
    link_to: "/bus/stores",
  },
  {
    name: "sideBar.Members",
    file: "members",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideMember} alt="sideMember" />,
    link_to: "/bus/members",
  },
  {
    name: "sideBar.Products",
    file: "business-products",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideProducts} alt="sideProduct" />,
    link_to: "/bus/business-products",
  },
  {
    name: "sideBar.Sales Orders",
    file: "business-orders",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideSale} alt="sideSale" />,
    link_to: "/bus/business-orders",
  },
  {
    name: "sideBar.Purchase Orders",
    file: "business-purchase",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sidePurchase} alt="sidePurchase" />,
    link_to: "/bus/business-purchase",
  },

  {
    name: "sideBar.Pending Approvals",
    file: "business-preOrders",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={Icon} alt="Icon" />,
    link_to: "/bus/business-preOrders",
  },

  {
    name: "sideBar.Prescriptions",
    file: "business-prescriptions",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={Pres} alt="Pres" />,
    link_to: "/bus/business-prescriptions",
  },

  {
    name: "sideBar.Profile",
    file: "profile",
    navigations: true,
    live: true,
    buttons: [],
    icon: <img src={sideProfile} alt="sideProfile" />,
    link_to: "/bus/profile",
  },
];
export default sidnavAdmin;
