import { useState, createContext, useEffect, useMemo, useRef } from "react";
import {
  connectionWithSocketServer,
  socketServer,
  forcefullyDisconnect,
} from "../realtimeCommunication/socketConnection";
import { addSocket } from "../services/socket";
import { setShouldReconnectSocket } from "../services/chat";
import { useDispatch, useSelector } from "react-redux";
import { resetStore } from "../services/auth";
import { useLocation, matchPath, useNavigate } from "react-router-dom";

const context = {};

export const SocketContext = createContext(context);

export function SocketContextProvider(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const { shouldReconnectSocket } = useSelector((state) => state?.chat);
  const { sockets } = useSelector((state) => state?.socket);
  const userStoreIdRef = useRef(user?.store?._id);
  const { store_name, _id, status } = user?.store || {};
  const [userSocket, setUserSocketData] = useState(null);
  const [storeSocket, setStoreSocketData] = useState(null);
  const [storeSocketId, setStoreSocketId] = useState("");
  const [userSocketId, setUserSocketId] = useState("");
  const [count, setCount] = useState(0);

  const createStore = (data) => {
    return data;
  };

  const store = useMemo(
    () => createStore({ store_name, _id, status }),
    [store_name, _id, status]
  );

  useEffect(() => {
    const userStoreId = user?.store?._id;
    if (
      (userStoreId != userStoreIdRef?.current || shouldReconnectSocket) &&
      user &&
      user?.email
    ) {
      if (userSocket) {
        userSocket.disconnect();
        userSocket.offAny();
      }

      if (sockets?.length) {
        if (socketServer()) {
          socketServer().emit("forceDisconnect", sockets);
        }

        dispatch(addSocket([]));
      }

      (async () => {
        let newUserSocket = await connectionWithSocketServer(user, (sockId) => {
          setUserSocketId(sockId);
        });

        if (newUserSocket) {
          setUserSocketData(newUserSocket);
        }
      })();

      if (user?.store) {
        setTimeout(() => {
          (async () => {
            let newStoreSocket = await connectionWithSocketServer(
              user?.store,
              (sockId) => {
                setStoreSocketId(sockId);
              }
            );
            if (newStoreSocket) {
              setStoreSocketData(newStoreSocket);
            }
          })();
        }, 2000);
      }
      userStoreIdRef.current = user?.store?._id;
      dispatch(setShouldReconnectSocket(false));
    } else {
      if (!user?.email) {
        dispatch(addSocket([]));
        // setUserSocketData(null);
        // setStoreSocketData(null);
        if (sockets?.length && socketServer()) {
          socketServer().emit("forceDisconnect", sockets);
        }
      }
    }
  }, [store]);

  useEffect(() => {
    if (!user?.email && socketServer()) {
      if (storeSocketId) {
        forcefullyDisconnect(storeSocket, [storeSocketId], function (res) {
          if (res) {
            setStoreSocketId("");
            setStoreSocketData(null);
          }
        });
      }

      if (userSocketId) {
        forcefullyDisconnect(userSocket, [userSocketId], function (res) {
          if (res) {
            setUserSocketId("");
            setUserSocketData(null);
          }
        });
      }
    }
  }, [storeSocketId?.length, userSocketId?.length, count]);

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        setCount((preCount) => preCount + 1);
        let action = "USER_LOGOUT";
        dispatch(resetStore(action, navigate));
        // onLogout();

        navigate("/login", { replace: true });
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const allContext = useMemo(
    () => ({
      user,
      userSocket,
      storeSocket,
      shouldReconnectSocket,
      setUserSocketData,
      setStoreSocketData,
    }),
    [
      user,
      userSocket,
      storeSocket,
      shouldReconnectSocket,
      setUserSocketData,
      setStoreSocketData,
    ]
  );

  return (
    <SocketContext.Provider value={allContext}>
      {props.children}
    </SocketContext.Provider>
  );
}
