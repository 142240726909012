import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";

import "./pricing.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import Icon1 from "../../../assets/businessPage/crossCirclefill.svg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="ConnectRxLandingPageContainer">
        <Box sx={{ background: "#35A6AE" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("pricing.title")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("pricing.descr")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={2} justifyContent="center">
          <Box className="pricingCardMain">
            <Box className="pricingCard">
              <Box className="imageIcon">
                <img src={Icon1} />
              </Box>
              <Typography className="heading">
                {t("pricing.cardTitle")}
              </Typography>
              <Typography className="title">
                lorem ipsum lorem ipsum lorem ipsum
              </Typography>
              <Box className="price">
                <Typography className="sign">$</Typography>
                <Typography className="digit">25</Typography>
              </Box>
              <Box className="cardBtn">
                <Button
                  sx={{
                    backgroundColor: "white !important",
                    color: "#35A6AE",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  {t("landingPage.loginBtn")}
                </Button>
              </Box>
            </Box>
            <Box className="pricingCard">
              <Box className="imageIcon">
                <img src={Icon1} />
              </Box>
              <Typography className="heading">
                {t("pricing.cardTitle")}
              </Typography>
              <Typography className="title">
                lorem ipsum lorem ipsum lorem ipsum{" "}
              </Typography>
              <Box className="price">
                <Typography className="sign">$</Typography>
                <Typography className="digit">25</Typography>
              </Box>
              <Box className="cardBtn">
                <Button
                  sx={{
                    backgroundColor: "white !important",
                    color: "#35A6AE",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  {t("landingPage.loginBtn")}
                </Button>
              </Box>
            </Box>
            <Box className="pricingCard">
              <Box className="imageIcon">
                <img src={Icon1} />
              </Box>
              <Typography className="heading">
                {t("pricing.cardTitle")}
              </Typography>
              <Typography className="title">
                lorem ipsum lorem ipsum lorem ipsum{" "}
              </Typography>
              <Box className="price">
                <Typography className="sign">$</Typography>
                <Typography className="digit">25</Typography>
              </Box>
              <Box className="cardBtn">
                <Button
                  sx={{
                    backgroundColor: "white !important",
                    color: "#35A6AE",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  {t("landingPage.loginBtn")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Pricing;
