import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"; // Import DatePicker from @mui/x-date-pickers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Import AdapterDayjs
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import logo from "../../assets/images/connectLogo.png";
import MailImg1 from "../../assets/businessPage/mail-img1.png";
import MailImg2 from "../../assets/businessPage/mail-img2.png";
import { Formik } from "formik";
import { queriesInitialValues, QueriesSchema } from "./helper";
import { ToastContainer } from "react-toastify";
import FErrorMessage from "../../shared/components/FErrorMessage";
import PhoneInput from "react-phone-input-2";
import { queryDetails } from "../../services/auth";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import QueriesImage from "../../assets/connectRxImages/quriesImage.svg";
import BackArrowButton from "../../shared/components/backarrowbutton/BackArrowButton";

const Queries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.queries?.loading);

  return (
    <>
      <BackArrowButton />
      <Formik
        initialValues={queriesInitialValues}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          dispatch(queryDetails(values, navigate));
        }}
        validationSchema={QueriesSchema}
      >
        {(props) => (
          <Box
            display="flex"
            flexDirection="column"
            sx={{ height: { xs: "100%", sm: "100vh" } }}
          >
            <Box
              className="logoContainer"
              justifyContent=" center"
              sx={{ cursor: "pointer", paddingTop: "2rem" }}
              onClick={() => navigate("/marketplace")}
            >
              <img src={logo} className="logo" />
            </Box>
            <Box
              width={{ xs: "90%", sm: "90%", md: "70%", lg: "70%", xl: "70%" }}
              margin={{ xs: "20px auto", sm: "20px auto", md: "50px auto", lg: "auto", xl: "auto" }}
              boxShadow="0 0 15px -5px #707070"
              borderRadius="44px"
              backgroundColor="#f9f9f9"
            >
              {/* spacing={1} */}
              <Grid container >
                <Grid item xs={12} lg={6} md={12}>
                  <form
                    onSubmit={props.handleSubmit}
                    style={{ padding: "30px 20px" }}
                  >
                    <Box mb={3}>
                      <Typography
                        sx={{
                          color: "#35A6AE",
                          fontWeight: "500",
                          fontSize: "22px",
                        }}
                      >
                        Schedule Meeting
                      </Typography>
                      <Typography color="#101828" fontWeight="400" pb={1}>
                        We're open for any suggestion or just to have a chat
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel shrink>First Name</InputLabel>
                        <TextField
                          fullWidth
                          className="authfield"
                          placeholder="First Name"
                          value={props.values.first_name}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          name="first_name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel shrink>Last Name</InputLabel>
                        <TextField
                          fullWidth
                          className="authfield"
                          placeholder="Last Name"
                          value={props.values.last_name}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          name="last_name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel shrink htmlFor="email-input">
                          Email
                        </InputLabel>
                        <TextField
                          fullWidth
                          className="authfield"
                          placeholder="Email"
                          value={props.values.email}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          name="email"
                          error={
                            props.touched.email && Boolean(props.errors.email)
                          }
                          type="email"
                          required
                        />
                        <FErrorMessage name="email" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel shrink>Phone Number</InputLabel>
                        <PhoneInput
                          international
                          className="authfield"
                          country="ca"
                          preferredCountries={["ca"]}
                          excludeCountries={["us"]}
                          disableInitialCountryGuess={false}
                          name="mobile_no"
                          autoComplete="mobile_no"
                          variant="filled"
                          onChange={(phone, country) => {
                            props.setFieldValue("mobile_no", phone);
                          }}
                          onBlur={props.handleBlur}
                          value={props?.values?.mobile_no}
                          required
                          style={{
                            height: "45px",
                            color: "#333",
                            border: "1px solid #d0d5dd",
                            boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "8px",
                            backgroundColor: "white",
                          }}
                          inputStyle={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                            background: "none",
                            padding: "8px 0px 8px 60px",
                            width: "100%",
                          }}
                          buttonStyle={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                            background: "none",
                          }}
                        >
                          {() => (
                            <TextField
                              fullWidth
                              label="Landline"
                              name="mobile_no"
                              value={props.values.mobile_no}
                            />
                          )}
                        </PhoneInput>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputLabel shrink>Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            value={props.values.date}
                            onChange={(date) => {
                              props.setFieldValue("date", date);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        width: { xs: "100%", md: "60%" },
                        margin: "auto",
                        marginTop: "24px",
                      }}
                    >
                      <Button
                        className="containedPrimary"
                        variant="contained"
                        sx={{ width: "100%", borderRadius: "16px !important" }}
                        onClick={props.handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <ClipLoader size={25} color="white" loading />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Box>
                    <ToastContainer />
                  </form>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={12} display={{ xs: "none", md: "block" }} >
                  {/* <Box > */}
                  <img src={QueriesImage} alt="IMG" width="100%" />
                  {/* style={{maxWidth:"100%",height:"auto"}}/> */}
                  {/* width="100%" */}
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};
export default Queries;
