import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/swiper-bundle.css";
import "./scss/styles.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Router from "./app/router/router";
import { store, persistor } from "./app/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { AuthContextProvider } from "./app/context/authContext";
import { SocketContextProvider } from "./app/context/socketContext";
import { FilterContextProvider } from "./app/context/FilterContext";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
export const history = createBrowserHistory();
window.process = {};
const Slide = cssTransition({
  enter: "slideIn",
  exit: "slideOut",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HistoryRouter history={history}>
    <ToastContainer />
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SocketContextProvider>
            <AuthContextProvider>
              <FilterContextProvider>
                <Router />
              </FilterContextProvider>
            </AuthContextProvider>
          </SocketContextProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </HistoryRouter>
);
